import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import "./styles.css";

const HeaderSimple = (props) => {

    return (        
        <header id="main-header" className="main-header-simple">
            <Container>
                <Row className="main-header-top main-header-top-logo">                               
                    <h1 className="main-header-top-logo">
                        <Link to="/">INFOR HOUSE</Link>
                    </h1>     
                </Row>
            </Container>
        </header>        
    )
}

export default HeaderSimple;