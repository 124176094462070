import React, { Component } from 'react';
import { Container, Row, Col, Button } from '../../../../node_modules/react-bootstrap';
import AdminNavigation from '../../../components/adminNavigation';
import api from '../../../services/api';
import BasicTable from '../../../components/basicTable';
import AdminModalCRUDShoppings from '../../../components/adminModalCRUDShoppings';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFilter } from '@fortawesome/free-solid-svg-icons';
import "./styles.css";
import BasicTableFilter from "../../../components/basicTableFilter";

export default class AdminShoppingsPage extends Component {
    constructor (props) {
        super(props);
        this.state = {
            page: 1,            
            pedidos : [],
            sizePerPage: 15,
            total: 0,
            showModal: false,
            pedido: {},
            showFilter: false,
            params_filter: {}
        }
    };    

    componentDidMount() {        
        this.buscarPedidos();
    }

    handleShowFilter = () => {
        this.setState({ showFilter: !this.state.showFilter });
    }

    handleOnClick = (id) => {
        this.buscarPedido(id);
        this.setState({showModal:true}); 
    };    

    buscarPedidos = async (page, size, sortOrder, sortField, filter) => {
        let complement = "";
        let pedidos = [];
        let total = 0;

        complement += (typeof(page) != "undefined") ? "page=" + page : "";
        complement += (typeof(size) != "undefined") ? "&size=" + size : "";
        complement += (typeof(sortOrder) != "undefined") ? "&sortOrder=" + sortOrder : "";
        complement += (typeof(sortField) != "undefined") ? "&sortField=" + sortField : "";
       
        if(complement.length <= 2){
            complement +="size=15&page=1";
        }

        if(filter){
            this.setState({params_filter: filter});
        }
        
        if (this.state.params_filter){
            complement += "&filter=" + encodeURIComponent(JSON.stringify(this.state.params_filter));
        }
        
        await api.get('/admin/pedidos?'+complement)
        .then(function (response) {
            pedidos = response.data.data; 
            total = response.data.total;                        
        });
        
        this.setState({ pedidos: pedidos, total: total });        
    }
  
    buscarPedido = async (id) => {
        let pedido={};

        await api.get('/admin/pedidos/'+id)
        .then(function (response) {
            pedido = response.data.data;           
        });
        
        this.setState({ pedido: pedido });
    }
    
    handleTableChange = (type, params) => {
        const { page, sizePerPage, sortOrder, sortField } = params;
    
        this.buscarPedidos(page, sizePerPage, sortOrder, sortField);
        this.setState({
            page,
            sizePerPage,
        });        
    };

    handleSubmitFilter = (params) => {         
        this.buscarPedidos(undefined, undefined, undefined, undefined, params);
        this.setState({
            page: 1,
            sizePerPage: 15
        });
    }

    handleEditPedido = async(id, param) => {
        
        await api.put('/admin/pedidos/' + id, param)
        .then(function (response) {            
            toast.success("Cadastro realizado com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            return true;
        })
        .catch(function (error) {
            let message_error = "";
            
            try {    
                message_error = typeof error.response.data.error != "undefined" ? error.response.data.error : "";
            }catch(er){};           

            toast.error("Erro no cadastro! " + message_error, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });

        return false;
    }

  
    handleModalClose = () => {
        this.setState({showModal:false, pedido: {}})
    };

    handleModalShow = () => {
      this.setState({showModal:true})      
    };

    render() {
        const { data, sizePerPage, page } = this.state;

        const columns = [
         {
            dataField: 'cliente_nome',
            text: 'Nome',
            sort: true,
            filter_form: {
                name: 'nome',
                type: "InputFilter"
            }
          },
          {
            dataField: 'cliente_celular',
            text: 'Whats',
            sort: true,
            filter_form: {
                name: 'celular',
                type: "InputFilter"
            },
            formatter: "fone"
          },
          
          {
            dataField: 'valor_total',
            text: 'Total',
            sort: true,
            formatter: "coin"
          },
          {
            dataField: 'inserted_at',
            text: 'Data Início',
            formatter: "date",
            sort: true,
            filter_form: {
                name: 'data_insercao',
                type: "InputFilter"
            },
          },
          {
            dataField: 'estado',
            text: 'Estado',
            sort: true,
            filter_form: {
                name: 'estado',
                type: "SelectFilter",
                valueSelected: null,
                onChange: () => {},
                options: [
                    {
                        value: 'Carrinho de Compras',
                        label: 'Carrinho de Compras'
                    },
                    {
                        value: 'Aguardando Separação',
                        label: 'Aguardando Separação'   
                    },
                    {
                        value: 'Em Separação',
                        label: 'Em Separação'   
                    },
                    {
                        value: 'Aguardando pagamento',
                        label: 'Aguardando pagamento'
                    },
                    {
                        value: 'Pago',
                        label: 'Pago'
                    },
                    {
                        value: 'Em Viagem',
                        label: 'Em Viagem'
                    },
                    {
                        value: 'Resolução de problema',
                        label: 'Resolução de problema'
                    },
                    {
                        value: 'Concluído',
                        label: 'Concluído'
                    },
                    {
                        value: 'Cancelado',
                        label:  'Cancelado'
                    }
                ]
            }
          }
        ];

        return (
            <Container className="content-body">            
                <Row>
                    <Col xs={10} md={2}>
                        <AdminNavigation props={this.props}/>
                    </Col>
                    <Col xs={10} md={10}>
                        <div className="table-actions-box">
                            <Button variant="secundary" onClick={this.handleShowFilter}>
                                <FontAwesomeIcon icon={faFilter} /> Filtro
                            </Button>
                        </div>

                        <AdminModalCRUDShoppings 
                            show={this.state.showModal} 
                            handleClose={this.handleModalClose} 
                            pedido={this.state.pedido}
                            handleEditPedido={this.handleEditPedido}
                            handleAfterChange={this.handleOnClick}
                         />
                        {this.state.showFilter && 
                            <BasicTableFilter
                                columns={columns}
                                handleSubmit={this.handleSubmitFilter}
                            />
                        }
                        <BasicTable 
                            data={ this.state.pedidos }
                            page={ page }
                            sizePerPage={ sizePerPage }
                            totalSize={ this.state.total }
                            onTableChange={ this.handleTableChange }
                            columns={columns}
                            title="Pedidos"
                            handleOnClickRow={this.handleOnClick}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
}