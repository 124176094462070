import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import AdminSalerProducts from '../../../components/adminSalerProducts';
import api from '../../../services/api';
import AdminSalerNewModal from '../../../components/adminSalerNewModal';
import {useParams, Link} from "react-router-dom";
import FormatterCoin from '../../../components/formatterCoin';

class AdminSalerMain extends Component {

    constructor (props) {
        super(props);
        this.state = {
            busca: null,
            produtos: [],
            showNew: false
        }
    };    

    handleSearche = async (e) => {
        e.preventDefault();
        let complement;
        let produtos;
        let total;
        complement += "page=1";
        complement += "&size=30";
        complement += "&sortOrder=ASC";
        complement += "&sortField=disponivel_venda";
        
        
        complement += "&filter=" + encodeURIComponent(JSON.stringify({nome: this.state.busca}));            
        
        await api.get('/produtos?'+complement)
        .then(function (response) {
            produtos = response.data.data; 
            total = response.data.total;                        
        });
        
        this.setState({ produtos: produtos });                
    };

    handleNovaVenda = () => {
        this.setState({showNew: true});
    }

    handleCloseNew = () => {
        this.setState({showNew: false});
    }

    render(){
        return(
            <div>
                <header id="main-header" className="main-header-simple admin-header-venda">
                    <Container>
                        <h1>VENDA</h1>
                        <Row>
                        <div className="search-bar"> 
                            <form onSubmit={this.handleSearche}>              
                                <input type="text" onChange={(param)=>{this.setState({busca: param.target.value})}}
                                    id="search" name="search" placeholder="O que você está procurando?" spellCheck="false" autoComplete="off" dir="auto" nm-autocomplete="off"
                                 />
                                 
                                <button type="submit" value="Buscar" id="btnOK" className="search-button">
                                    <FontAwesomeIcon icon={faSearch} />    
                                </button>
                            </form>
                        </div> 
                    </Row>
                    </Container>
                </header>
                <Container>                    
                     {!this.props.pedido_carrinho.id &&
                        <Row>
                            <button className="btn-nova-venda" onClick={this.handleNovaVenda}> <FontAwesomeIcon icon={faPlus} /></button>                        
                        </Row>
                    }
                    {this.props.pedido_carrinho.id &&
                        <Link to={"/pedido/" + this.props.pedido_carrinho.id} className="ver-carrinho">                                       
                            <FormatterCoin valor={this.props.pedido_carrinho.valor_total} />                      
                            <p className="ver-carrinho-aux">
                                <FontAwesomeIcon icon={faShoppingCart} />  
                                <span> Carrinho</span>
                            </p>
                        </Link>
                    }
                    <div className="infor-venda">
                        <AdminSalerProducts produtos={this.state.produtos} handlePageChange={this.handlePageClick} usuario={this.props.usuario} pedido_carrinho={this.props.pedido_carrinho} buscarPedidoCarrinho={this.props.buscarPedidoCarrinho} />
                    </div>
                </Container>
                <AdminSalerNewModal usuario={this.props.usuario} show={this.state.showNew} handleClose={this.handleCloseNew} />
            </div>
        );
    }
}

export default AdminSalerMain;