import React, { Component } from 'react';
import { Container, Row, Col, Button } from '../../../../node_modules/react-bootstrap';
import AdminNavigation from '../../../components/adminNavigation';
import api from '../../../services/api';
import AdminFormNotifyUsers from '../../../components/adminFormNotifyUsers';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

export default class AdminNotify extends Component {
    constructor (props) {
        super(props);
        this.state = {
            param: null,
            show: false
        }
    };    

    handleSubmit = (param) =>{
        this.setState({param: param, show: true});
    }

    handleClose = () => {
        this.setState({show: false});
    }

    handleNotify = async() => {
        
        await api.put('/admin/usuarios/notificacao/', this.state.param)
        .then(function (response) {
            toast.success("Mensagem enviada com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            return true;
        })
        .catch(function (error) {
            let message_error = "";
            
            try {    
                message_error = typeof error.response.data.error != "undefined" ? error.response.data.error : "";
            }catch(er){};           

            toast.error("Erro no cadastro! " + message_error, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });

        this.handleClose()

        return false;
    }

    render() {
        return (
            <Container className="content-body">            
                <Row>
                    <Col xs={10} md={2}>
                        <AdminNavigation props={this.props}/>
                    </Col>
                    <Col>
                        <h3>Notificação</h3>
                        <AdminFormNotifyUsers handleSubmit={this.handleSubmit}/>
                    </Col>
                </Row>
                <Modal show={this.state.show} onHide={this.handleClose} dialogClassName="modal-container">
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar envio
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>                    
                        <span>Essa mensagem será enviada para todos os usuários/clientes. Deseja continuar?</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={this.handleNotify}>
                            Confirmar
                        </Button>
                        </Modal.Footer>
                </Modal>
            </Container>
        );
    }
}