import React,  {useState, useEffect} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import "./styles.css";

const AdminFormNotifyUsers = (props) => {
    const [toEdit, setToEdit] = useState(false);
    const [show, setShow] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    return (
        <div>
            <Formik
                enableReinitialize    
                initialValues={{msg: ''}}             
                validate={values => {
                    const errors = {};
                    if (!values.msg) {
                        errors.msg = 'Mensagem é obrigatório!';
                    }
                    return errors;
                }}
                onSubmit={ (values, {resetForm}) => {
                    setIsSubmitting(true);
                    
                    let param = {
                        msg : values.msg
                    };

                    setIsSubmitting(false);   
                    if ( props.handleSubmit(param) ){
                        resetForm({});
                    }                       
                    setIsSubmitting(false);                           
                }                }
                >
                {formik  => (
                    <Form className="form-padrao">
                        <label htmlFor="msg">Mensagem</label>
                        <Field className="notify-text" type="text" name="msg" placeholder="Mensagem será enviada para TODOS os clientes ativos" component="textarea"/>
                        <ErrorMessage name="msg" component="div" className="error-message"/>                       

                        <button type="submit" disabled={isSubmitting}>
                            Enviar
                        </button>
                    </Form>
                )}
                </Formik>
                <ToastContainer />
        </div>
    );
}

export default AdminFormNotifyUsers;