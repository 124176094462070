import api from '../../services/api';

const PedidoCarrinhoSessao = async () => {
    let pedido = {};
    
    await api.get('/usuario/pedidos/carrinho')
        .then(function (response) {
            if(response.data.data){
                pedido = response.data.data;                             
            }
        })
        .catch(function (error) {
            // handle error
            pedido = {
                error: true
            }
        })

    return pedido;
}

export {PedidoCarrinhoSessao};