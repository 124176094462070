import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormatterCoin from '../formatterCoin';
import { ButtonToolbar } from 'react-bootstrap';
import env from "../../config";

const VendaModalAddProduto = (props) => {
    const [qtd, setQtd] = useState(1);

    const handleAddProduto = async (prod) => {            
        let pedido = {
            pedido_produto :{
                produtos_id: props.produto.id,
                quantidade: qtd
            }
        };
        
        let pedido_id;

        // Adiciona novo pedido
        if (!props.pedido_carrinho.id){
            await api.post('/usuario/' + props.usuario.id + "/pedidos")
                .then(function (response) {                       
                    pedido_id = response.data.data.id;                
                    toast.success("Novo pedido iniciado com sucesso!", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });                          
                })
                .catch(function (error) {                        
                    toast.erro("Erro ao adicionar novo pedido!", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                });                                                              
        }   

        if(!pedido_id){
            pedido_id = props.pedido_carrinho.id;
        }

        await api.post('/usuario/' + props.usuario.id + "/pedidos/" + pedido_id + "/produtos", pedido)
            .then(function (response) {           
                toast.success("Produto adicionado ao carrinho!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                handleClose();
                // Atualiza o estado global de carrinho
                props.buscarPedidoCarrinho();
            })
            .catch(function (error) {                        
                toast.warn("ERRO! Verifique se o produto já estava adicionado ao seu carrinho. Alteração de quantidade pode ser feita no carrinho!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
        });        
        
    }

    // Atualiza a quantidade ao fechar modal
    const handleClose = () =>{
        setQtd(1);
        props.handleClose();
    }

    return (
      <>     
        <Modal show={props.show} onHide={()=>{handleClose()}} dialogClassName="modal-container">
          <Modal.Header closeButton>
          <Modal.Title>{props.produto.nome} - 
            {props.pedido_carrinho.id && props.pedido_carrinho.atacado &&
                <FormatterCoin valor={props.produto.preco_atacado} />
            }
            {!props.pedido_carrinho.id &&
                <FormatterCoin valor={props.produto.preco_atacado} />
            }
            {props.pedido_carrinho.id && !props.pedido_carrinho.atacado &&
                <FormatterCoin valor={props.produto.preco_varejo} />
            }
          </Modal.Title>
          </Modal.Header>
          <Modal.Body className="form-padrao">  
            {props.produto.produtos_fotos &&
            <img className="products-card-image" src={env.url_files + props.produto.produtos_fotos.id +"-"+ props.produto.produtos_fotos.filename } />   
            }
            <label>Quantidade </label>                      
            <input type="number" onChange={ 
                            e => { 
                                let newValue = parseInt(e.target.value); 
                                if(newValue < 1){
                                    newValue = 1;
                                }
                                setQtd(newValue);
                            }}
                            value={qtd}
            />          
          </Modal.Body>
          <Modal.Footer>
                {props.pedido_carrinho.id && props.pedido_carrinho.atacado &&
                    <h5> Sub Total: <FormatterCoin valor={qtd*props.produto.preco_atacado} /></h5>  
                }
                {!props.pedido_carrinho.id &&
                    <h5> Sub Total: <FormatterCoin valor={qtd*props.produto.preco_atacado} /></h5>  
                }
                {props.pedido_carrinho.id && !props.pedido_carrinho.atacado &&
                    <h5> Sub Total: <FormatterCoin valor={qtd*props.produto.preco_varejo} /></h5>  
                }
                                
              <ButtonToolbar>
                  <button className="btn-confirmar" onClick={()=>{handleAddProduto()}}>Confirmar</button>
              </ButtonToolbar>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </>
    );
  }

  export default VendaModalAddProduto;