import React, { useState } from 'react';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import AdminFormCRUDProduct from '../adminFormCRUDProduct';
import './styles.css';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import env from "../../config";
import { ToastContainer, toast } from 'react-toastify';


const ModalDeleteImg = (props) => {
  return (
  <Modal show={props.show} dialogClassName="modal-container">
    <Modal.Header closeButton>
        <Modal.Title>Exluir Imagem?</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div >
          <Button variant="danger" type="submit" onClick={()=>{
            props.handleDeleteImage(props.imgId);
            props.handleCloseModal();
          }}> Confirmar Exclusão </Button>
        </div>        
    </Modal.Body>
  </Modal>
  )
}

const ProductImages = (props) => {
  let array_img = props.images ? props.images : [];
  const [show, setShow] = useState(false);
  const [imgId, setImgId] = useState(null);

  const handleCloseModal = () => {
    setShow(false);
  }

  const handleOnClickDeleteImage = (id) => {
    setShow(true);
    setImgId(id);
  }

  return (
    <>
      <ModalDeleteImg show={show} handleCloseModal={handleCloseModal} imgId={imgId} handleDeleteImage={props.handleDeleteImage} />
      {array_img.map( (img) => {
        return (
          <div>
            <img src={env.url_files + img.id +"-"+ img.filename }/>
            <Button variant="danger" onClick={()=>{handleOnClickDeleteImage(img.id)}}> X </Button>
          </div>
        )
      })}
    </>
  );
}

const Standard = (props) => {
  const getUploadParams = () => {
    return { url: env.url_api + '/admin/produtos/'+props.produto_id+'/fotos' }
  }

  return (
    <div>
      <div className="image-mini-upload">
        <ProductImages images={props.fotos} handleDeleteImage={props.handleDeleteImage}/>
      </div>
      <Dropzone
        accept="image/*"
        getUploadParams={getUploadParams}
        styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
        inputContent={(files, extra) => (extra.reject ? 'Clique ou arraste para adicionar fotos' : 'Arraste aqui a imagem para adicionar')}
      />
    </div>
  )
}

const AdminModalCRUDProduct = (props) => {
    return (
      <>        
        <Modal show={props.show} onHide={props.handleClose} dialogClassName="modal-container">
          <Modal.Header closeButton>
            <Modal.Title>Produto</Modal.Title>
          </Modal.Header>
          <Modal.Body>                    
            <Tabs defaultActiveKey="produto" id="uncontrolled-tab-example">
              <Tab eventKey="produto" title="Produto">
                <AdminFormCRUDProduct 
                  selectOptions={props.selectOptions} 
                  produto={props.produto} 
                  handleCloseMainModal={props.handleClose}
                  handleAddProduct={props.handleAddProduct} 
                  handleEditProduct={props.handleEditProduct} 
                  handleAfterChange={props.handleAfterChange} 
                  handleAfterDepartamentos={props.handleAfterDepartamentos} 
                  handleAfterCategorias={props.handleAfterCategorias} 
                  handleDeleteProduto={props.handleDeleteProduto}
                />
              </Tab>
              <Tab eventKey="fotos" title="Fotos" disabled={!props.produto.id}>
                <Standard produto_id={props.produto.id} fotos={props.produto.fotos} handleDeleteImage={props.handleDeleteImage}/>   
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
        <ToastContainer />
      </>
    );
  }
  
  export default AdminModalCRUDProduct;