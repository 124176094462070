import React, {Component, useState, useEffect} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Modal, Button, Tabs, Tab } from '../../../node_modules/react-bootstrap';
import './styles.css';
import { Container } from '../../../node_modules/react-bootstrap';
import { ToastContainer, toast } from '../../../node_modules/react-toastify';
import { useHistory, useLocation } from "react-router-dom";
import api from '../../services/api';
import { Link } from "react-router-dom";

function replaceTel(param){        
    param=param.replace(/[^\d]+/g,'');             //Remove tudo o que não é dígito
    if (param.length > 3){
        param=param.replace(/(\d{2})(\d{5,})/g, (regex, arg1, arg2) => {
            arg2=arg2.replace(/(\d{5})(\d)/g, (reg, param1, param2) => { return param1+ '-' + param2});
            return '(' + arg1 + ') ' + arg2
        }); //Coloca parênteses em volta dos dois primeiros dígitos
    }
    return param;
}

const FormLogin = (props) => {
    const [showModal, setShowModal] = useState(false);
    let history_nav = useHistory();
    let location = useLocation();
    const [celularForm, setCelularForm] = useState("");
    
    if (location.search.search("error=authAdmin") > 0 && props.foi_notificado == false) {        
        setTimeout(function(){    
        toast.error( "Você precisa estar logado com uma conta de ADMINISTRADOR para acessar essa ação", {
            position: toast.POSITION.BOTTOM_RIGHT
        });
        }, 300);
        props.setFoiNotificado();
    }
    
    if (location.search.search("error=authVendedor") > 0 && props.foi_notificado == false) {        
        setTimeout(function(){    
        toast.error( "Você precisa ser VENDEDOR para acessar essa ação.", {
            position: toast.POSITION.BOTTOM_RIGHT
        });
        }, 300);
        props.setFoiNotificado();
    }

    const handleModalClose = () => {
        setShowModal(false)
    }

    const handleModalShow = () => {
        setShowModal(true)
    }

    return (
        <div>
            <UsuarioRecuperarSenha 
                    show={showModal} 
                    handleRecuperarSenha={props.handleRecuperarSenha}
                    handleClose={handleModalClose} 
            />
            <Formik
                initialValues={{ celular: '', senha: ''}}
                validate={values => {
                    const errors = {};
                    if (!celularForm) {
                        errors.celular = 'Seu contato do Whatsapp é obrigatório!';
                    } 
                    if ( !values.senha ) {
                        errors.senha = 'A senha é obrigatória!';
                    }
                    return errors;
                }}
                onSubmit={ (values, {setSubmitting, setErrors, setStatus, resetForm}) => {
                    let param = {                                                
                        senha: values.senha,
                        celular: celularForm.replace(/[^\d]+/g,'')
                    };

                    api.post('/usuarios/login', param)
                    .then(function (response) {
                        // handle success
                        setSubmitting(false);

                        props.handleLogin();

                        if ( response.data.data != null && response.data.data.usuario != null && response.data.data.usuario.login_senha_temporaria ) {
                            history_nav.push("/usuario?atualizar_senha=true"); 
                        }
                        else{
                            if ( response.data.data != null && response.data.data.usuario != null && response.data.data.usuario.admin ){
                                history_nav.push("/admin");                   
                            }
                            else{
                                history_nav.push("/");                   
                            }
                        }
                    })
                    .catch(function (error) {

                        let message_error = "Problema ao realizar login.";
                        if (typeof error.response != "undefined" && typeof error.response.data != "undefined") {
                            message_error = typeof error.response.data.error != "undefined" ? error.response.data.error : "";
                        }
                        
                        setSubmitting(false);   

                        toast.error( message_error, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    });
                }}
                >
                {({ isSubmitting }) => (
                    <Form className="form-login">

                        <label htmlFor="celular">Whatsapp(DDD+NUMERO)</label>
                        <Field type="text" name="celular" placeholder="00000000000" maxlength="15" onChange={ 
                            e => { 
                                let newValue = replaceTel(e.target.value); 
                                setCelularForm(newValue);
                            }}
                            value={celularForm}
                        />
                        <ErrorMessage name="celular" component="div" className="error-message"/>
                        
                        <label htmlFor="senha">Senha</label>
                        <Field type="password" name="senha" placeholder="Senha"/>
                        <ErrorMessage name="senha" component="div" className="error-message"/>                        
                        
                        <div className="submit-box">
                            <button type="submit" disabled={(isSubmitting)}>
                                Entrar
                            </button>
                        </div>

                        
                        <div className="link-options">
                            <a href="#" onClick={()=>{handleModalShow()}}>Recuperar Senha </a>
                            <Link to="/cadastro"> Novo Cadastro</Link>
                        </div>
                    </Form>
                )}
                </Formik>
                <ToastContainer />
        </div>
    );
}

const UsuarioRecuperarSenha = (props) => {
    const [show, setShow] = useState(false);

    useEffect(()=>{
        if(props.show) {
            setShow(true);
        }
        else{
            setShow(false);
        }
    }, [props.show])

    return (
        <>        
            <Modal show={show} onHide={props.handleClose} dialogClassName="modal-container">
            <Modal.Header closeButton>
                <Modal.Title>Recuperar Senha</Modal.Title>
            </Modal.Header>
            <Modal.Body>                    
            <Formik
                initialValues={{ celular: ''}}
                validate={values => {
                    const errors = {};
                    if (!values.celular) {
                        errors.celular = 'Seu contato do Whatsapp é obrigatório!';
                    }
                    return errors;
                }}
                onSubmit={ (values, {setSubmitting, setErrors, setStatus, resetForm}) => {
                    

                    api.put('/usuarios/'+values.celular+'/senha_temporaria')
                    .then(function (response) {
                        // handle success
                        setSubmitting(false); 
                        toast.success( "Sua senha temporária foi enviada por SMS para seu celular", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });       
                        props.handleClose();          
                    })
                    .catch(function (error) {

                        let message_error = "Problema ao recuperar senha.";
                        try{
                            if (typeof error.response != "undefined" && typeof error.response.data != "undefined") {
                                message_error = typeof error.response.data.error != "undefined" ? error.response.data.error : "";
                            }
                        }catch(e){};

                        setSubmitting(false);  

                        toast.error( message_error, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        props.handleClose();          
                    });
                }}
                >
                {({ isSubmitting }) => (
                    <Form className="form-login form-recuperar-senha">

                        <label htmlFor="celular">Whatsapp(DDD+NUMERO)</label>
                        <Field type="number" name="celular" placeholder="00000000000"/>
                        <ErrorMessage name="celular" component="div" className="error-message"/>
                        
                        <div className="submit-box submit-box-info">
                            <button type="submit" disabled={(isSubmitting)}>
                                Recuperar
                            </button>
                        </div>
                    </Form>
                )}
                </Formik>
                <ToastContainer />
            </Modal.Body>
            </Modal>
        </>
    )
}

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foi_notificado: false,
            showModal: false
        };
    }
    
    handleFoiNotificado = () => {
        this.setState({foi_notificado: true})
    }

    handleModalClose = () => {
        this.setState({showModal:false})
    };

    handleModalShow = () => {
      this.setState({showModal:true})
    };

    render() {        
        return (
            <Container className="content-body">
                <h1>Login Atacadista</h1>
                <p className="body-login-subtitle">
                    Realize o login para ter acesso aos melhores preços para revenda
                </p>
                <FormLogin 
                    api={this.props.api} 
                    foi_notificado={this.state.foi_notificado} 
                    setFoiNotificado={this.handleFoiNotificado}                    
                    handleRecuperarSenha={this.props.handleRecuperarSenha} 
                    handleLogin={this.props.handleLogin}                   
                />                
            </Container>
        );
    }
}