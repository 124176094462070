import React, { Component, useEffect, useState } from 'react';
import { Container, Button, ButtonToolbar, Col, Row } from 'react-bootstrap';
import api from '../../services/api';
import FormatterCoin from '../../components/formatterCoin';
import FormatterDataPadrao from '../../components/formatterDataPadrao';
import ShoppingCartProductsTable from '../../components/shoppingCardProductsTable';
import ShoppingCartDelivery from '../../components/shoppingCardDelivery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';
import env from "../../config";
import './styles.scss';


const ModalDesconto = (props) => {
    const [desconto, setDesconto] = useState(0);
    const [usuarioId, setUsuarioId] = useState(0);

    useEffect(()=>{
        setDesconto(props.produto.desconto);
    }, props.produto.desconto);

    const handleDesconto = (prod) => {            
        let pedido = {
            pedido_produto :{
                desconto: parseFloat(desconto)
            }
        };

        api.put('/admin/pedidos/' + props.pedido.id + "/produtos/" + props.produto.produtos_id, pedido)
            .then(function (response) {           
                toast.success("Desonto alterado sucesso!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                handleClose();
                    setTimeout(()=>{window.location.reload();
                }, 1500);
            })
            .catch(function (error) {                        
                toast.error("Erro ao alterar desconto.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
        });        
    }

    // Atualiza a quantidade ao fechar modal
    const handleClose = () =>{        
        props.handleClose();
        setDesconto(0);
    }

    return (
      <>        
        <Modal show={props.show_desconto} onHide={()=>{handleClose()}} dialogClassName="modal-container">
          <Modal.Header closeButton>
          <Modal.Title>{props.produto.nome} 
            {props.pedido.atacado &&
                    <FormatterCoin valor={props.produto.preco_atacado} />
                }
                {!props.pedido.atacado &&
                    <FormatterCoin valor={props.produto.preco_varejo} />
                }
          </Modal.Title>
          </Modal.Header>
          <Modal.Body className="form-padrao">   
            {props.produto.produtos_fotos &&
                <img className="products-card-image" src={env.url_files + props.produto.produtos_fotos[0].id +"-"+ props.produto.produtos_fotos[0].filename } />   
            }  
            <label>Desconto: </label>          
            <br/>
            <input type="number" onChange={ 
                            e => { 
                                setDesconto(e.target.value);
                            }}
                            value={desconto}
            />          
          </Modal.Body>
          <Modal.Footer>              
                {props.pedido.atacado &&
                    <h5> Sub Total: <FormatterCoin valor={props.produto.preco_atacado-desconto} /></h5>  
                }
                {!props.pedido.atacado &&
                    <h5> Sub Total: <FormatterCoin valor={props.produto.preco_varejo-desconto} /></h5>  
                }
              <ButtonToolbar>
                  <button className="btn-confirmar" onClick={()=>{handleDesconto()}}>Confirmar</button>
              </ButtonToolbar>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </>
    );
  }


const ModalAlerarQtd = (props) => {
    const [qtd, setQtd] = useState(0);
    const [usuarioId, setUsuarioId] = useState(0);

    useEffect(()=>{
        setQtd(props.produto.quantidade);
    }, props.produto.quantidade);

    const handleAlterarProduto = (prod) => {            
        let pedido = {
            pedido_produto :{
                quantidade: qtd
            }
        };

        api.put('/usuario/' + props.pedido.core_usuarios_id + "/pedidos/" + props.pedido.id + "/produtos/" + props.produto.produtos_id, pedido)
            .then(function (response) {           
                toast.success("Quantidade alterada com suceso!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                handleClose();
                    setTimeout(()=>{window.location.reload();
                }, 1500);
            })
            .catch(function (error) {                        
                toast.error("Erro ao alterar quantidade.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
        });        
    }

    // Atualiza a quantidade ao fechar modal
    const handleClose = () =>{        
        props.handleClose();
    }

    return (
      <>        
        <Modal show={props.show} onHide={()=>{handleClose()}} dialogClassName="modal-container">
          <Modal.Header closeButton>
          <Modal.Title>{props.produto.nome} - 
            {props.pedido.atacado &&
                    <FormatterCoin valor={props.produto.preco_atacado} />
                }
                {!props.pedido.atacado &&
                    <FormatterCoin valor={props.produto.preco_varejo} />
                }
          </Modal.Title>
          </Modal.Header>
          <Modal.Body className="form-padrao">   
            {props.produto.produtos_fotos &&
                <img className="products-card-image" src={env.url_files + props.produto.produtos_fotos[0].id +"-"+ props.produto.produtos_fotos[0].filename } />   
            }  
            <label>Quantidade: </label>          
            <br/>
            <input type="number" onChange={ 
                            e => { 
                                let newValue = parseInt(e.target.value); 
                                if(newValue < 1){
                                    newValue = 1;
                                }
                                setQtd(newValue);
                            }}
                            value={qtd}
            />          
          </Modal.Body>
          <Modal.Footer>
              
            {props.pedido.atacado &&
                    <h5> Sub Total: <FormatterCoin valor={qtd*props.produto.preco_atacado} /></h5>  
                }
                {!props.pedido.atacado &&
                    <h5> Sub Total: <FormatterCoin valor={qtd*props.produto.preco_varejo} /></h5>  
                }
              <ButtonToolbar>
                  <button className="btn-confirmar" onClick={()=>{handleAlterarProduto()}}>Confirmar</button>
              </ButtonToolbar>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </>
    );
  }


  const ModalExcluir = (props) => {
    
    const handleExcluirProduto = (prod) => {            
        
        api.delete('/usuario/' + props.pedido.core_usuarios_id + "/pedidos/" + props.pedido.id + "/produtos/" + props.produto.produtos_id)
            .then(function (response) {           
                toast.success("Produto Excluido com sucesso!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                handleClose();
                    setTimeout(()=>{window.location.reload();
                }, 1500);
            })
            .catch(function (error) {                        
                toast.error("Erro ao excluir.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
        });        
    }

    // Atualiza a quantidade ao fechar modal
    const handleClose = () =>{        
        props.handleCloseExcluir();
    }

    return (
      <>        
        <Modal show={props.show_excluir} onHide={()=>{handleClose()}} dialogClassName="modal-container">
          <Modal.Header closeButton>
          <Modal.Title>Excluir</Modal.Title>
          </Modal.Header>
          <Modal.Body>     
          {props.produto.produtos_fotos &&
                <img className="products-card-image" src={env.url_files + props.produto.produtos_fotos[0].id +"-"+ props.produto.produtos_fotos[0].filename } />   
            }  
            Remover {props.produto.nome} do seu carrinho?
          </Modal.Body>
          <Modal.Footer>        
              <ButtonToolbar>
                  <button className="btn-confirmar" onClick={()=>{handleExcluirProduto()}}>Sim</button>
              </ButtonToolbar>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </>
    );
  }


class ShoppingCartPage extends Component {
    constructor (props) {
        super(props);
        this.state = {
            pedido: {
                produtos: []
            },
            produto_alterar: {},
            show: false,
            show_excluir: false,
            produto_excluir: {},
            abaEntrega: false,
            show_desconto: false,
            produto_desconto: {},
        }
    };

    componentDidMount() {
        const { match: { params } } = this.props;                 
        this.buscarProdutos(params.id);    
        if (params.sub && params.sub === "entrega") {
            this.setState({abaEntrega: true})
        }
    }    

    buscarProdutos = async (pedido_id) => {
        let pedido={};

        await api.get('/usuario/pedidos/'+pedido_id)
            .then(async (response) => {
                pedido = response.data.data;                  
                
                await api.get('/usuario/pedidos/'+pedido_id+'/produtos/')
                .then(async (res_prod) => {
                    pedido.produtos = res_prod.data.data;                                        
                });
            })
            .catch(function (error) {
                toast.error("Erro ao buscar o pedido!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            });    

        this.setState({ pedido: pedido });        
    }

    handleFinalizarCompra = () => {
        const pedido_id = this.state.pedido.id;
        api.put('/usuario/pedidos/' + this.state.pedido.id + "/concluir/")
            .then(function (response) {           
                toast.success("Produto Concluído com sucesso!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });

                setTimeout(()=>{window.location.assign("/pedido/" + pedido_id + "/entrega");
                }, 1000);
            })
            .catch(function (error) {                        
                toast.error("Erro ao concluir.", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
        });     
    }

    handleOnClickQtd = (prod) => {
        this.setState({produto_alterar: prod});
        this.setState({show: true});
    }

    handleOnClickExcluir = (prod) => {
        this.setState({produto_excluir: prod});
        this.setState({show_excluir: true});
    }

    handleOnClickDesconto = (prod) => {
        this.setState({produto_desconto: prod});
        this.setState({show_desconto: true});
    }

    render () {
        return (
            <Container>
                <div class="container-fluid">
                <br /><br />
                <ul class="list-unstyled multi-steps">
                    {this.state.pedido.estado == "Carrinho de Compras" && !this.state.abaEntrega &&
                        <>
                            <li class="is-active">Pedido</li>
                            <li>Entrega</li>
                            <li>Revisão</li>
                        </>
                    }
                    
                    {this.state.abaEntrega &&
                        <>
                            <li>Pedido</li>
                            <li class="is-active">Entrega</li>
                            <li>Revisão</li>
                        </>
                    }   

                    {this.state.pedido.estado != "Carrinho de Compras" && !this.state.abaEntrega &&
                        <>
                            <li>Pedido</li>
                            <li>Entrega</li>
                            <li class="is-active">Revisão</li>
                        </>
                    }                    
                </ul>
                </div>
                {this.state.abaEntrega &&
                    <ShoppingCartDelivery pedido={this.state.pedido} usuario={this.state.usuario}/>
                }
                {!this.state.abaEntrega &&
                <>
                    <div className="sc-general-infor">                        
                        <table className="sc-table-top">
                            <tr>
                                <td>
                                    <tr> 
                                        <h3>Total: <strong><FormatterCoin valor={this.state.pedido.valor_total}/></strong></h3>
                                    </tr>
                                    <tr>
                                        <p><b>Cliente: </b> {this.state.pedido.cliente_nome} - {this.state.pedido.cliente_celular}</p>
                                    </tr>     
                                    <tr>
                                        <p><b>Estado: </b> {this.state.pedido.estado} </p>
                                    </tr>                                    
                                    <tr> 
                                        <p><b>Venda: </b> {this.state.pedido.vendedor_nome} </p>
                                    </tr>
                                </td>                                
                                <td>
                                    <tr>
                                        <td>   
                                            {this.state.pedido.estado == "Carrinho de Compras" &&
                                                <button onClick={()=>this.handleFinalizarCompra()} className="btn-confirmar">Finalizar Compra</button>                    
                                            }
                                        </td>
                                        <td>
                                            {(this.state.pedido.estado == "Aguardando Separação" || this.state.pedido.estado == "Em, Separação") &&
                                                <button onClick={()=>window.location.assign("/pedido/" + this.state.pedido.id + "/entrega")} className="btn-confirmar no-print">Alterar Entrega</button>                    
                                            }
                                        </td>
                                    </tr>
                                    {this.state.pedido.core_enderecos_id &&                                        
                                        <tr>
                                            <p><b>Endereço: </b> {this.state.pedido.endereco_logradouro}, {this.state.pedido.endereco_numero} - {this.state.pedido.endereco_bairro} / {this.state.pedido.endereco_complemento} 
                                                <br/> {this.state.pedido.endereco_cidade} - {this.state.pedido.endereco_estado} / CEP: {this.state.pedido.endereco_cep}
                                            </p>
                                        </tr>      
                                    }                                    
                                </td>                                
                            </tr>
                            {(this.state.pedido.estado == "Aguardando Separação" || this.state.pedido.estado == "Em, Separação") &&
                            <tr>
                                <td>
                                    <strong>Conferência: </strong>
                                </td>
                            </tr>      
                            }                            
                        </table>
                    </div>
                                    
                    <ShoppingCartProductsTable 
                        usuario={this.props.usuario} 
                        pedido={this.state.pedido} 
                        handleOnClickExcluir={this.handleOnClickExcluir} 
                        handleOnClickQtd={this.handleOnClickQtd}
                        handleOnClickDesconto={this.handleOnClickDesconto}
                    />
                        
                    <ModalAlerarQtd 
                        show={this.state.show} 
                        pedido={this.state.pedido} 
                        produto={this.state.produto_alterar} 
                        handleClose={()=>{this.setState({show: false})}} 
                    /> 
                    <ModalDesconto 
                        show_desconto={this.state.show_desconto} 
                        pedido={this.state.pedido} 
                        produto={this.state.produto_desconto} 
                        handleClose={()=>{this.setState({show_desconto: false})}} 
                    /> 
                    <ModalExcluir 
                        show_excluir={this.state.show_excluir} 
                        pedido={this.state.pedido} 
                        produto={this.state.produto_excluir} 
                        handleCloseExcluir={()=>{this.setState({show_excluir: false})}}
                    /> 
                    <p>Itens: <strong>{this.state.pedido.qtd_itens}</strong> / Data de Início: <strong><FormatterDataPadrao date={this.state.pedido.inserted_at}/></strong></p>                
                </>
                }
            </Container>
        );
    }
}

export default ShoppingCartPage;