const env = {
    url_api: "http://inforhousecariri.com.br/api/",
    url_files: "http://inforhousecariri.com.br/files/",
    cors_local: "",
    // cors_local: "https://cors-anywhere.herokuapp.com/",
    departamentos: [
        {
            id: "0449b563-f2ba-4f50-9bf2-3cc5e78cabe5",
            nome: "Informática",
            url: "informatica",
            categorias: [
                {
                    id: "ae7a8184-488f-4974-bd00-8de999a5da54",
                    nome: "Mouse",
                    url: "mouse"
                },
                {
                    id: "2f649570-87db-45c4-b494-f395d5ed1300",
                    nome: "Teclado",
                    url: "teclado",
                },
                {
                    id: "45af773a-aca0-45e4-94b7-9851495d15ce",
                    nome: "Gamer",
                    url: "gamer"
                },
                {
                    id: "ed049e81-23cc-4b4d-8beb-b7b65e827ad8",
                    nome: "Cabos",
                    url: "cabos"
                },
                {
                    id: "fad67058-8247-420a-8742-d1fe267e7019",
                    nome: "Adaptadores",
                    url: "adaptadores"
                }                
            ]
        },
        {
            id: "a61725bf-d706-4359-9ed0-dccf53fc82ae",
            nome: "Celulares e acessórios",
            url: "celulares-e-acessorios",
            categorias: [
                {
                    id: "db014216-fa32-42f6-816d-ea5e31a3c744",
                    nome: "Carregadores",
                    url: "carregadores"
                },
                {
                    id: "762ec1f4-b0c3-4c0d-9d4e-0a60b7f1ef6d",
                    nome: "Cabos",
                    url: "cabos"
                },
                {
                    id: "ff3f42b7-4c9a-4fbe-bfc9-dc80b8b516ba",
                    nome: "Capinhas",
                    url: "capinhas"
                },
                {
                    id: "e85376b0-7d8c-441b-aa8c-1f812343834d",
                    nome: "Carregadores Portáteis",
                    url: "portateis"
                },
                {
                    id: "49053adc-2deb-4c15-9e50-adf04845b7a0",
                    nome: "Adaptadores",
                    url: "adaptadores"
                },
                {
                    id: "0c636f93-80d7-404a-b01e-a85ded086b1b",
                    nome: "Smartwatches",
                    url: "smartwatches"
                },
                {
                    id: "8f86ab69-aae0-457c-98c8-5ef58d008c21",
                    nome: "Suport Veicular",
                    url: "suporte-veicular"
                }                
            ]
        },
        {
            id: "556246d5-f631-45d7-b8cf-3a2561b8c758",
            nome: "Caixinhas",
            url: "caixinhas",
            categorias: [
                {
                    id: "8bd57e3a-f6ec-480b-909b-64bae10c2f75",
                    nome: "Caixinhas Portáteis",
                    url: "caixinhas-portateis"
                },
                {
                    id: "8fde35da-eaed-4622-8083-6f4fab4ea2e0",
                    nome: "Rádios Retrô",
                    url: "radio-retro"
                }
            ]
        },        
        {
            id: '16f8376f-02ff-434d-abe7-1e81ca7b7aec',
            nome: 'Fones de Ouvido',
            url: "fones-de-ouvido",
            categorias: [
                {
                    id: '94fe5a4c-e068-435c-9356-8987bf50f4d2',
                    nome: 'Fone com Fio',
                    url: "com-fio"
                },
                {
                    id: '428f16cb-4d8d-432f-bdc3-4d99c235d884',
                    nome: 'Fone Bluetooth',
                    url: 'bluetooth'
                },
                {
                    id: '86a08d64-80ca-4b68-a700-bc849c99c5df',
                    nome: 'Headphone',
                    url: 'headphone'
                }
            ]          
        },
        {
            id: "43f209c2-7427-4b24-8634-fe005bd05b77",
            nome: "TV e Som",
            url: "tv-e-som",
            categorias: [
                {
                    id: "19e524ca-2c33-42d4-a9de-948c8412ff7e",
                    nome: "Cabos",
                    url: "cabos"
                },
                {
                    id: "7d4ab231-97dc-4177-bdfa-4419194daa17",
                    nome: "Adaptadores",
                    url: "adaptadores"
                }
            ]
        },
        {
            id: "4a068ff5-401d-43e2-90c2-bbee41ea130e",
            nome: "Casa e Decoração",
            url: "casa-e-decoracao",
            categorias: [
                {
                    id: "87081a6a-a669-4139-b813-f168c6a3daa8",
                    nome: "Relógios",
                    url: "relogios"
                },
                {
                    id: "06cb2ffb-fd19-4d75-91b4-09404a8f5699",
                    nome: "Cozinha",
                    url: "cozinha"
                },
                {
                    id: "840f967c-8d4d-4992-bc0c-d670e44d5957",
                    nome: "Beleza",
                    url: "beleza"
                },
                {
                    id: "6526a829-45fc-40f0-9b05-2944d38a56ed",
                    nome: "Abajur",
                    url: "abajur"
                },
                {
                    id: "ee13bb94-fb89-42d0-9497-5ce2aa84f922",
                    nome: "Saúde",
                    url: "saude"
                },
                {
                    id: "9f2f15bb-594c-4ca1-b4e2-b803fc42eaf1",
                    nome: "Decoração",
                    url: "decoracao"
                }                
            ]
        },
        {
            id: "3f5099fa-b882-11ea-a350-53795b3f16da",
            nome: "Ferramentas",
            url: "ferramentas",
            categorias: [
                {
                    id: "27dcfc69-1fee-4ccc-a836-aaf23206cf80",
                    nome: "Ferramentas",
                    url: "ferramentas"
                }                
            ]
        },
        {
            id: "0fcd642e-b882-11ea-a34f-c7926ca4438d",
            nome: "Pendrive e Cartão",
            url: "pendrive-cartao",
            categorias: [
                {
                    id: "4dd3f7a0-d1d1-4244-94ce-bcb41ff8aa7b",
                    nome: "Pendrive e Cartão",
                    url: "pendrive-cartao"
                }                
            ]
        },
        {
            id: "d9857a9c-b880-11ea-a34e-6717b88ea2fb",
            nome: "Calculadoras",
            url: "calculadoras",
            categorias: [
                {
                    id: "2ed610d8-c55b-419d-a71d-658ab9984bc0",
                    nome: "Calculadoras",
                    url: "calculadoras"
                }                
            ]
        },
        {
            id: "1e376f66-b885-11ea-a352-e38c4393d265",
            nome: "Gamers e Brinquedos",
            url: "gamer",
            categorias: [
                {
                    id: "862b4361-3023-4932-95bd-a752af8b653b",
                    nome: "Gamers e Brinquedos",
                    url: "gamer"
                }                
            ]
        },
        {
            id: "c72ee3ba-b885-11ea-a353-134ced8ae04e",
            nome: "Lanternas",
            url: "lanternas",
            categorias: [
                {
                    id: "c72ee3ba-b885-11ea-a353-134ced8ae04e",
                    nome: "Lanternas",
                    url: "lanternas"
                }                
            ]
        },
        {
            id: "64bca555-b45a-43da-a9ca-afe255a5bdec",
            nome: "Mais Tech",
            url: "tech",
            categorias: [
                {
                    id: "813f4c18-66c3-4e7f-88dd-133d0600d3e7",
                    nome: "Mais Tech",
                    url: "tech"
                }                
            ]
        }
    ]
};

export default env;