import React from 'react';
import Carousel from 'nuka-carousel';
import "./styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import banner01 from "../../assets/banner/banner-01.png";
import banner02 from "../../assets/banner/banner-02.png";
import banner03 from "../../assets/banner/banner-03.png";
import banner04 from "../../assets/banner/banner-04.png";


const SlideMain = () => {
    const colors = ["7732bb", "047cc0", "00884b", "e3bc13", "db7c00", "aa231f"];

    return (
        <div className="carousel-main">        
             <Carousel
                autoplay={true}
                renderCenterLeftControls={({ previousSlide }) => (
                    <button onClick={previousSlide}><FontAwesomeIcon icon={faChevronLeft} /></button>
                )}
                renderCenterRightControls={({ nextSlide }) => (
                    <button onClick={nextSlide}><FontAwesomeIcon icon={faChevronRight} /></button>
                )}
             
             >                
                    <img
                        src={banner02}
                        alt="Na Infor House você encontra entrega Grátis em Juazeiro do Norte"
                        // key={color}
                        style={{
                            height:400
                        }}
                    />
                    <img
                        src={banner03}
                        alt="Na Infor House você encontra entrega Grátis em Juazeiro do Norte"
                        // key={color}
                        style={{
                            height:400
                        }}
                    />
                    <img
                        src={banner04}
                        alt="Na Infor House você encontra entrega Grátis em Juazeiro do Norte"
                        // key={color}
                        style={{
                            height:400
                        }}
                    />
            </Carousel>
        </div>
    );
};

export default SlideMain;
