import React, { useState, useEffect } from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import './styles.css';
import 'react-dropzone-uploader/dist/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';

const AdminModalCRUDUser = (props) => {
    const [estadoVenda, setEstadoVenda] = useState({});

    const onChange = (param) => {
        setEstadoVenda(param);
    }
    
    const selectOption = [
        {
            value: 'Carrinho de Compras',
            label: 'Carrinho de Compras'
        },
        {
            value: 'Aguardando Separação',
            label: 'Aguardando Separação'   
        },
        {
            value: 'Em Separação',
            label: 'Em Separação'   
        },
        {
            value: 'Aguardando pagamento',
            label: 'Aguardando pagamento'
        },
        {
            value: 'Pago',
            label: 'Pago'
        },
        {
            value: 'Em Viagem',
            label: 'Em Viagem'
        },
        {
            value: 'Resolução de problema',
            label: 'Resolução de problema'
        },
        {
            value: 'Concluído',
            label: 'Concluído'
        },
        {
            value: 'Cancelado',
            label:  'Cancelado'
        }
    ]

    useEffect(()=>{
        console.log(props.pedido.estado);
        setEstadoVenda(
            {
                label: props.pedido.estado,
                value: props.pedido.estado
            }        
        );
    }, [props.pedido.estado]);

    const handleAtualizar = () => {
        let param = {
            pedido: {
                estado: estadoVenda.value
            }
        };

        props.handleEditPedido(props.pedido.id, param)
    }

    return (
        <>        
        <Modal show={props.show} onHide={props.handleClose} dialogClassName="modal-container">
          <Modal.Header closeButton>
            <Modal.Title>{props.pedido.cliente_nome}                
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>                    
            <Tabs defaultActiveKey="produto" id="uncontrolled-tab-example">
              <Tab eventKey="produto" title="Produto">
                    <p>Estado</p>                        
                    <Select
                        name="estado"
                        value={estadoVenda}
                        onChange={onChange}
                        options={selectOption}
                        className="select-box"
                    />
                    <button className="btn-confirmar" onClick={()=>handleAtualizar()}>Atualizar</button>

              </Tab>
              <Tab eventKey="comunicacao" title="Comunicação">
                <ul>
                    <li>
                        <a href={"/pedido/"+props.pedido.id} target="_blank">Detalhes</a>
                    </li>
                    <li>
                        <a href={"https://api.whatsapp.com/send?1=pt_BR&phone=55"+props.pedido.cliente_celular+"&text=" + encodeURIComponent("*INFO HOUSE*\n\n Estado: *" + props.pedido.estado + "*\n\n")} target="_blank">Whatsapp Estado</a>                        
                    </li>
                    <li>
                        <a href={"https://api.whatsapp.com/send?1=pt_BR&phone=55"+props.pedido.cliente_celular+"&text=" + encodeURIComponent("*INFO HOUSE*\n\n Estado: *" + props.pedido.estado + "*\n\n A separação do pedido irá ocorrer após a confirmação do pagamento, por favor nos avise se já tiver realizado. Obrigado pela preferência :)")} target="_blank">Whatsapp Aviso Separação</a>
                    </li>
                    <li>
                        <a href={"https://api.whatsapp.com/send?1=pt_BR&phone=55"+props.pedido.cliente_celular+"&text=" + encodeURIComponent("*INFO HOUSE*\n\n Estado: *" + props.pedido.estado + "*\n\n Seu pedido já está pronto! para solicitar a entrega mais rapidamente, basta ligar para esse número :)")} target="_blank">Tudo pronto</a>
                    </li>
                    <li>
                        <a href={"https://api.whatsapp.com/send?1=pt_BR&phone=55"+props.pedido.cliente_celular+"&text=" + encodeURIComponent("*INFO HOUSE*\n\n Estado: *" + props.pedido.estado + "*\n\n Infelizmente tivemos que cancelar seu pedido :( \n\nNosso prazo máximo é de três dias para confirmação do pagamento. Qualquer dúvida conte comigo.")} target="_blank">Cancelado</a>
                    </li>                    
                </ul>        
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
        <ToastContainer />
      </>
    );
  }
  
  export default AdminModalCRUDUser;