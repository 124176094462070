import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles.css';

const Footer = () => {
    return (
        <div className="footer-body">
            <Container>
                {/* <Row>
                    <Col>
                        <h2>InforVendas</h2>
                    </Col>
                    <Col>
                        <h2>Categorias</h2>
                    </Col>
                    <Col>
                        <h2>Contato</h2>
                    </Col>
                </Row> */}
                
                <Row>
                    <p>
                        Rua São Pedro, 1226 - Juzeiro do norte-CE<br/>
                        Infor Distribuidora<br/>
                        &copy; <small>Desenvolvido por INFOR DEV</small>
                    </p>
                </Row>
            </Container>
        </div>
    );
}

export default Footer;