import React, { useState } from 'react';
import VendaModalAddProduto from '../../components/vendaModalAddProduto';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, Row, Container, ListGroup } from 'react-bootstrap';
import {useParams, Link} from "react-router-dom";
import FormatterCoin from '../formatterCoin';
import env from "../../config";
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const AdminSalerProducts = (props) => {        
    const [show, setShow] = useState(false);
    const [produtoAdd, setProdutoAdd] = useState({});

    const handleComprar = (prod) => {
        let text;

        setProdutoAdd(prod);
        setShow(true);
    }

    return (
        <div style={{width: '100%'}}>
            
            <table className="table-shopping-cart">        
                {props.produtos && props.produtos.map( (prod, i) =>{ 
                    return (
                    <tr>
                        <td className="td-normal">
                            {prod.produtos_fotos &&
                                <img src={env.url_files + prod.produtos_fotos.id +"-"+ prod.produtos_fotos.filename } />
                            }
                        </td>
                        <td className="td-nome">                                
                            {prod.nome}
                        </td>
                        {props.pedido_carrinho.atacado &&
                            <td className="td-normal venda-preco-atacado">A  <FormatterCoin valor={prod.preco_atacado} /> </td>
                        }
                        {!props.pedido_carrinho.atacado &&
                            <td className="td-normal venda-preco-varejo">V  <FormatterCoin valor={prod.preco_varejo} /> </td>
                        }
                        {props.pedido_carrinho.id &&
                            <td> <FontAwesomeIcon icon={faPlusCircle} onClick={()=>handleComprar(prod)} className="add-item-venda"/></td>
                        }
                    </tr>
                    )
                })}
        </table>   
            <VendaModalAddProduto show={show} produto={produtoAdd} handleClose={()=>{setShow(false)}} pedido_carrinho={props.pedido_carrinho} usuario={props.usuario} buscarPedidoCarrinho={props.buscarPedidoCarrinho}/>
            <ToastContainer />
        </div>        
    )
}

export default AdminSalerProducts;