import React,  {useState, useEffect} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import './styles.css';

function replaceTel(param){        
    param=param.replace(/[^\d]+/g,'');             //Remove tudo o que não é dígito
    if (param.length > 3){
        param=param.replace(/(\d{2})(\d{5,})/g, (regex, arg1, arg2) => {
            arg2=arg2.replace(/(\d{5})(\d)/g, (reg, param1, param2) => { return param1+ '-' + param2});
            return '(' + arg1 + ') ' + arg2
        }); //Coloca parênteses em volta dos dois primeiros dígitos
    }
    return param;
}

const AdminFormCRUDUser = (props) => {
    const [toEdit, setToEdit] = useState(false);
    const [show, setShow] = useState(false);
    const [celularForm, setCelularForm] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleCloseModal = () => {
        setShow(false);
        props.handleCloseMainModal();
    }    

    useEffect(()=>{
        if (props.usuario.celular){
            setCelularForm(replaceTel(props.usuario.celular));
        }
    }, props.usuario.celular);

    const handleSenhaPadrao = () => {
        let param = {
            usuario: {
                senha: "123456"
            }
        };
        props.handleEditUser(props.usuario.id, param);
    };

    return (
        <div>
            <Formik
                enableReinitialize 
                initialValues={props.usuario}
                validate={values => {
                    const errors = {};
                    if (!values.nome) {
                        errors.nome = 'Nome é obrigatório!';
                    } 
                    if ( !celularForm ) {
                        errors.celular = 'Celular é obrigatório!';
                    }
                    return errors;
                }}
                onSubmit={ (values, {resetForm}) => {  
                    setToEdit(false);                  
                    if(props.usuario.id){
                        let param = {
                            usuario :{                              
                            }
                        };
                        
                        if (values.nome != props.usuario.nome){
                            param.usuario.nome = values.nome;
                        }

                        let celular_aux = celularForm.replace(/[^\d]+/g,'');

                        if (celular_aux != props.usuario.celular){
                            param.usuario.celular = celular_aux;
                        }

                        if (values.ativo != props.usuario.ativo){
                            param.usuario.ativo = values.ativo;
                        }

                        if (values.vendedor != props.usuario.vendedor){
                            param.usuario.vendedor = values.vendedor;
                        }

                        if ( props.handleEditUser(props.usuario.id, param) ){
                            props.handleAfterChange(props.usuario.id);
                            setToEdit(false);
                        }
                        setToEdit(false);
                    }
                    else{
                        let celular_aux = celularForm.replace(/[^\d]+/g,'');

                        let param = {
                            usuario :{
                                nome: values.nome,
                                celular: celular_aux,
                                ativo: values.ativo,     
                                vendedor: values.vendedor                                                         
                            }
                        };

                        if ( props.handleAddUser(param) ){
                            resetForm({});
                        }                                                 
                    }                    
                }}
                >
                {({ isSubmitting }) => (
                    <Form className="form-padrao">
                        <label htmlFor="nome">Nome</label>
                        <Field type="text" name="nome" placeholder="Nome do usuario" disabled={props.usuario.id && !toEdit} />
                        <ErrorMessage name="nome" component="div" className="error-message"/>

                        <label htmlFor="celular">Celular</label>
                        <Field  maxlength="15" minlength="15" type="text" name="celular" placeholder="Celular do usuario" disabled={props.usuario.id && !toEdit} 
                             onChange={ 
                                e => { 
                                    let newValue = replaceTel(e.target.value); 
                                    setCelularForm(newValue);
                                }}
                                value={celularForm}
                        />
                        <ErrorMessage name="celular" component="div" className="error-message"/>
                       

                        {/* Permite alterar cancelado e visivel para venda somente na edição */}
                        {(props.usuario.id) &&
                            <>
                                <div className="form-check-box">
                                    <label htmlFor="ativo">Ativo</label>
                                    <Field
                                            name="ativo"
                                            render={({ field, form }) => {
                                            return (
                                                <input
                                                type="checkbox"
                                                id="ativo"
                                                className=""
                                                disabled={props.usuario.id && !toEdit}
                                                checked={field.value}
                                                {...field}
                                                />
                                            );
                                            }}
                                        />
                                </div>
                                
                                <div className="form-check-box">
                                    <label htmlFor="vendedor">Vendedor</label>
                                    <Field
                                            name="vendedor"
                                            render={({ field, form }) => {
                                            return (
                                                <input
                                                type="checkbox"
                                                id="vendedor"
                                                className=""
                                                disabled={props.usuario.id && !toEdit}
                                                checked={field.value}
                                                {...field}
                                                />
                                            );
                                            }}
                                        />
                                </div>
                            </>
                        }

                        
                        <div className="submit-box">                            
                            {(props.usuario.id && toEdit==true) &&
                                <div>
                                    <button className="cancel-color" type="button" onClick={() => {setToEdit(false)}}>
                                        Cancelar
                                    </button> 
                                    <button type="submit">
                                        Enviar
                                    </button> 
                                </div>
                            }
                            
                            {(props.usuario.id && toEdit==false) &&
                                <>
                                    <div>
                                        <button className="infor-color" type="button" onClick={() => {handleSenhaPadrao()}}>
                                            Senha Padrão
                                        </button> 
                                    </div>
                                    <div>
                                        <button className="infor-color" type="button" onClick={() => {setToEdit(true)}}>
                                            Editar
                                        </button> 
                                    </div>
                                </>
                            }        
                            {!props.usuario.id &&
                                <button type="submit">
                                    Cadastrar
                                </button>
                            }
                        </div>
                    </Form>
                )}
                </Formik>
                <ToastContainer />
        </div>
    );
}

export default AdminFormCRUDUser;