import React, { useState } from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import 'react-dropzone-uploader/dist/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import api from '../../services/api';
import './styles.css';

function replaceTel(param){        
    param=param.replace(/[^\d]+/g,'');             //Remove tudo o que não é dígito
    if (param.length > 3){
        param=param.replace(/(\d{2})(\d{5,})/g, (regex, arg1, arg2) => {
            arg2=arg2.replace(/(\d{5})(\d)/g, (reg, param1, param2) => { return param1+ '-' + param2});
            return '(' + arg1 + ') ' + arg2
        }); //Coloca parênteses em volta dos dois primeiros dígitos
    }
    return param;
}

const AdminSalerNewModal = (props) => {
    const [celular, setCelular] = useState('');
    const [clienteId, setClienteId] = useState();
    const [clienteNome, setClienteNome] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleCliente = (celular) => {
        api.get("/venda/usuario/cliente/" + celular.replace(/[^\d]+/g,''))
            .then(function (response){
                setClienteId(response.data.data.id);
                setClienteNome(response.data.data.nome);
            })
    }

    const handleNewPedido = async (atacado) => {
        let cliente_id = clienteId;

        setIsSubmitting(true);
        
        if (!clienteId && clienteNome && celular){

            let param_usu = {
                usuario :{
                    nome: clienteNome,
                    celular: celular.replace(/[^\d]+/g,'')
                }
            }

            await api.post('/usuarios', param_usu)
                    .then(function (response) {     
                        setClienteId(response.data.data.id);
                        cliente_id=response.data.data.id;
                    })
                    .catch(function (error) {                        

                        toast.info("Não foi possível cadastrar o cliente", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    });    
        }
        
        let param = {
            pedido: {
                atacado: atacado,
                core_usuarios_cliente_id: cliente_id
            }
        };

        await api.post("/venda/pedidos", param)
            .then(function (response) {     
                toast.success("Venda Iniciada com SUCESSO!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                window.location.assign("/venda");
            })
            .catch(function (error) {                        

                toast.error("Não foi possível iniciar a venda!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            });  

        setIsSubmitting(false);
        
    }

    return (
      <>        
        <Modal show={props.show} onHide={props.handleClose} dialogClassName="modal-container">
          <Modal.Header closeButton>
            <Modal.Title>NOVA VENDA</Modal.Title>
          </Modal.Header>
          <Modal.Body>        
              <div className="form-padrao">
                <label>Whatsapp</label>
                <input minLength='15' maxLength="15" type="text" onChange={ 
                            e => { 
                                let newValue = replaceTel(e.target.value); 
                                setCelular(newValue);
                                if(newValue.length >= 15) {
                                    handleCliente(newValue);
                                }
                                else{
                                    setClienteNome('');
                                    setClienteId(null);
                                }
                            }} 
                            value={celular}
                    />
                  <label>Cliente</label>        
                  <input minLength='2' maxLength="125" type="text" onChange={ 
                            e => { 
                                setClienteNome(e.target.value);                                
                            }} 
                            value={clienteNome}
                            disabled={clienteId}
                    />      
            </div>    
            <div className="buttons-venda">
                {!isSubmitting &&
                    <>
                    <button className="button-atacado" onClick={()=>handleNewPedido(true)}>Atacado</button>
                    <button className="button-varejo" onClick={()=>handleNewPedido(false)}>Varejo</button>
                    </>
                }
               
                {isSubmitting &&
                    <div class="lds-facebook"><div></div><div></div><div></div></div>
                }
            </div>            
            
          </Modal.Body>
        </Modal>
        <ToastContainer />
        
      </>
    );
  }
  
  export default AdminSalerNewModal;