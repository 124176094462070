import React,  {useState, useEffect} from 'react';
// import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import "./styles.css";

const InputFilter = (props) => {

    const handleChange = (e) => {
        let values = props.values;
        values[e.target.name] = e.target.value;        
        props.setValues(values);
    }

    return (
        <>        
            <input type="text" name={props.data.filter_form.name} placeholder={props.data.text} onChange={handleChange} />
        </>
    );
}

const SelectFilter = (props) => {
    const [selectOptions, setSelectOptions] = useState(null);

    useEffect(()=>{
        setSelectOptions(props.data.options);
    }, [props.data.options]);

    const handleChange = (e) => {
        let values = props.values;
        values[props.data.filter_form.name] = e.value;        
        props.setValues(values);
        props.data.filter_form.onChange(e.value);
    }

    return (
        <Select
            name={props.data.filter_form.name}
            value={props.data.valueSelected}
            onChange={handleChange}
            options={props.data.filter_form.options}
            placeholder={props.data.text}
            className="select-box"
        />
    );
}

const ProcessInputFormatter = (props) => {

  
    return (
        <>
            {props.columns.map( (item, i) => {
                if (item.filter_form && item.filter_form.type == "InputFilter") {            
                    return ( <InputFilter values={props.values} setValues={props.setValues} data={item}/> );
                }
                if (item.filter_form && item.filter_form.type == "SelectFilter") {
                    return ( <SelectFilter values={props.values} setValues={props.setValues} data={item} /> );
                }            
            })}
        </>
    );
}

const BasicTableFilter = (props) => {
    const [values, setValues] = useState({});

    const onSubmit = (event) => {
        props.handleSubmit(values);        
        event.preventDefault();
    }

    return (
        <div>
            <form className="form-padrao form-filter" onSubmit={onSubmit}>
                
                <ProcessInputFormatter  columns={props.columns} values={values} setValues={setValues} />                          
                <div className="submit-box">                            
                    <button type="submit" >
                        Buscar
                    </button>
                </div>
            </form>
        </div>
    )
}

export default BasicTableFilter;