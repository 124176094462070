import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';

const MenuVenda = (props) => {
    const [usuario, setUsuario] = useState({});

    useEffect(()=>{        
        if (props.usuario){
           setUsuario(props.usuario);
        }        
    }, [props.usuario]);

    return (
        <>
        
            {usuario.vendedor &&
                <button className="btn-nova-venda" onClick={()=>window.location.assign("/venda")}> <FontAwesomeIcon icon={faClipboard} /></button>          
            }
        </>
    )
};

export default MenuVenda;
            