import React, { useState, useEffect } from 'react';

const FormatterDataPadrao = (props) => {
    const [date, setDate] = useState('');

    useEffect(()=>{
        if(props.date){
            setDate("" + new Date(props.date).toLocaleDateString() + " " + new Date(props.date).toLocaleTimeString());
        }
    }, props.date);

    return (
        <span>{date}</span>
    )
}

export default FormatterDataPadrao;