import React, {useEffect} from 'react';
import { Route } from 'react-router-dom';
import AdminProducts from '../adminProducts';
import AdminUsers from '../adminUsers';
import AdminNotify from '../adminNotify';
import AdminHeader from '../../../components/adminHeader';
import { useHistory } from "react-router-dom";
import AdminShoppingsPage from '../adminShoppings';


const AdminRoutes = (props) => {
    let history = useHistory();

    useEffect(()=>{        
        setTimeout(()=>{
            if ( props.usuario && (props.usuario.error || props.usuario.admin == false) ) {        
                history.push("/login?error=authAdmin");
            }
        }, 1000);        
    }, [props.usuario]);

    return (
        <div>
            <AdminHeader usuario={props.usuario} />
            {/* note the addition of the exact property here */}
            <Route exact path={props.match.url} component={AdminProducts} />
            <Route path={props.match.url + "/produtos"} component={AdminProducts} />
            <Route path={props.match.url + "/usuarios"} component={AdminUsers} /> 
            <Route path={props.match.url + "/notificacao"} component={AdminNotify} />            
            <Route path={props.match.url + "/pedidos"} component={AdminShoppingsPage} />            
        </div>
    );
};

export default AdminRoutes;

