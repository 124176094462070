import React, {Component} from 'react';
import "./styles.css";
import api from "../../services/api";
import SlideMain from '../../components/slideMain';
import FeaturedProducts from '../../components/featuredProducts';
import { Container } from 'react-bootstrap';

export default class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            productInfo : {},
            page: 1
        }
    }

    // componentDidMount() {
    //     this.loadProducts();
    // }

    // loadProducts = async (page = 1) => {      
        
    //     const response = await api.get("/products?page="+page);
    //     const { docs, ...productInfo } = response.data;
    //     this.setState({ products: docs, productInfo, page })
    // };

    nextPage = () => {
        const { page, productInfo } = this.state;

        if (page === productInfo.pages) return;

        const pageNumber = page+1;

        this.loadProducts(pageNumber);
    };

    prevPage = () => {
        const { page } = this.state;

        if (page === 1) return;

        const pageNumber = page-1;

        this.loadProducts(pageNumber);
    };

    render() {
        const { page, productInfo} = this.state;

        const products=[
            {
                img: "https://images-americanas.b2w.io/produtos/01/00/img/48333/3/48333317_1GG.jpg",
                description: "Ar condicionado multi uso Brasteamp",
                value: "R$ 34,90"
            },
            {
                img: "https://images-americanas.b2w.io/produtos/01/00/img/48333/3/48333317_1GG.jpg",
                description: "Ar condicionado multi uso Brasteamp",
                value: "R$ 34,90"
            },
            {
                img: "https://images-americanas.b2w.io/produtos/01/00/img/48333/3/48333317_1GG.jpg",
                description: "Ar condicionado multi uso Brasteamp",
                value: "R$ 34,90"
            },
            {
                img: "https://images-americanas.b2w.io/produtos/01/00/img/48333/3/48333317_1GG.jpg",
                description: "Ar condicionado multi uso Brasteamp",
                value: "R$ 34,90"
            },
            {
                img: "https://images-americanas.b2w.io/produtos/01/00/img/48333/3/48333317_1GG.jpg",
                description: "Ar condicionado multi uso Brasteamp",
                value: "R$ 34,90"
            },
            {
                img: "https://images-americanas.b2w.io/produtos/01/00/img/48333/3/48333317_1GG.jpg",
                description: "Ar condicionado multi uso Brasteamp",
                value: "R$ 34,90"
            },
        ];
    

        return (
            <Container>
                <SlideMain />
                {/* <FeaturedProducts title="Novidades" color="#F00" products={products}/> */}
                {/* <FeaturedProducts title="Faça a Festa" color="#000" products={products}/>
                <FeaturedProducts title="Infor Dicas" color="rgba(0, 90, 255, 1)" products={products}/>
                <FeaturedProducts title="Mais Vendidos" color="rgb(0, 230, 103)" products={products}/> */}

                {/* <div className="product-list">
                    {products.map( product => (
                        <article key={product._id}>
                            <strong>{product.title}</strong>
                            <p>{product.description}</p>
                            <Link to={`product/${product._id}`}>Acessar</Link>                    </article>
                    ))}

                    <div className="actions">
                        <button disabled={page===1} onClick={this.prevPage}>Anterior</button>
                        <button disabled={page===productInfo.pages} onClick={this.nextPage}>Próximo</button>
                    </div>
                </div> */}
            </Container>
        );
    }
}