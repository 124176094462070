import React, { Component } from 'react';
import { Container, Row, Col, Button } from '../../../../node_modules/react-bootstrap';
import AdminNavigation from '../../../components/adminNavigation';
import api from '../../../services/api';
import BasicTable from '../../../components/basicTable';
import AdminModalCRUDProduct from '../../../components/adminModalCRUDProduct';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFilter } from '@fortawesome/free-solid-svg-icons';
import "./styles.css";
import BasicTableFilter from "../../../components/basicTableFilter";

export default class AdminProducts extends Component {
    constructor (props) {
        super(props);
        this.state = {
            page: 1,            
            produtos : [],
            sizePerPage: 15,
            total: 0,
            selectOptions: {
                marcas: [],
                departamentos: [],
                categorias: [],
                subCategorias: []
            },
            showModal: false,
            produto: {},
            showFilter: false,
            params_filter: {}
        }
    };    

    componentDidMount() {        
        this.buscarProdutos();
    }

    handleShowFilter = () => {
        
        this.handleSelectOptions();

        this.setState({ showFilter: !this.state.showFilter });
    }

    handleSelectOptions = async () => {
        let departamentos = [];
        let marcas = [];

        await api.get('/admin/produtos/departamentos')
            .then(function (response) {
               departamentos = response.data.data.map(function(departamento) {
                return {value: departamento.id, label: departamento.nome};
              });
            });
        
        await api.get('/admin/produtos/marcas')
            .then(function (response) {
                marcas = response.data.data.map(function(categoria) {
                    return {value: categoria.id, label: categoria.nome};
                });
            });

        if (this.state.produto && this.state.produto.id){
            this.handleAfterDepartamentos(this.state.produto.produtos_departamentos_id);
        }
        
        if (this.state.produto && this.state.produto.id){
            this.handleAfterCategorias(this.state.produto.produtos_categorias_id);
        }

        this.setState({ selectOptions : {marcas: marcas, departamentos: departamentos}});            
    }

    handleAfterDepartamentos = async (id) => {
        let categorias = [];

        // Caso a função seja chamada pelo componente de Filtro
        if ( id.value ){
            id = id.value;
        }

        await api.get('/admin/produtos/departamentos/' + id + '/categorias')
            .then(function (response) {
                categorias = response.data.data.map(function(categoria) {
                    return {value: categoria.id, label: categoria.nome};
                });
            });        
        let selectOptionsAux = this.state.selectOptions;

        selectOptionsAux.categorias = categorias;
        
        this.setState({ selectOptions : selectOptionsAux}); 
    }

    handleAfterCategorias = async (id) => {
        let subCategorias = [];

        await api.get('/admin/produtos/categorias/' + id + "/subcategorias")
            .then(function (response) {
                subCategorias = response.data.data.map(function(subCategoria) {
                    return {value: subCategoria.id, label: subCategoria.nome};
                });
            });
        
        let selectOptionsAux = this.state.selectOptions;
        selectOptionsAux.subCategorias = subCategorias;
        
        this.setState({ selectOptions : selectOptionsAux}); 
    }

    handleOnClick = (id) => {
        this.buscarProduto(id);
        this.handleSelectOptions();
        this.setState({showModal:true}); 
    };    

    buscarProdutos = async (page, size, sortOrder, sortField, filter) => {
        let complement = "";
        let produtos = [];
        let total = 0;

        complement += (typeof(page) != "undefined") ? "page=" + page : "";
        complement += (typeof(size) != "undefined") ? "&size=" + size : "";
        complement += (typeof(sortOrder) != "undefined") ? "&sortOrder=" + sortOrder : "";
        complement += (typeof(sortField) != "undefined") ? "&sortField=" + sortField : "";

        if(complement.length <= 2){
            complement +="size=15&page=1"
        }

        if(filter){
            this.setState({params_filter: filter});
        }
        
        if (this.state.params_filter){
            complement += "&filter=" + encodeURIComponent(JSON.stringify(this.state.params_filter));
        }
        
        await api.get('/admin/produtos?'+complement)
        .then(function (response) {
            produtos = response.data.data; 
            total = response.data.total;                        
        });
        
        this.setState({ produtos: produtos, total: total });        
    }

    
    buscarProduto = async (id) => {
        let produto={};

        await api.get('/admin/produtos/'+id)
        .then(function (response) {
            produto = response.data.data;
            
            api.get('/admin/produtos/'+id+"/fotos")
                .then(function (res) {
                    let fotos = res.data.data;
                    produto.fotos = fotos;                 
                });              
        });

        
        this.setState({ produto: produto });
    }
    
    handleTableChange = (type, params) => {
        const { page, sizePerPage, sortOrder, sortField } = params;
    
        this.buscarProdutos(page, sizePerPage, sortOrder, sortField);
        this.setState(() => ({
            page,
            sizePerPage,
        }));        
    };

    handleSubmitFilter = (params) => {        
        this.buscarProdutos(undefined, undefined, undefined, undefined, params);
        this.setState({
            page: 1,
            sizePerPage: 15
        });
    }

    handleAddProduct = async(param, handleOnClick=this.handleOnClick) => {
        
        await api.post('/admin/produtos', param)
        .then(function (response) {
            let dados = {
                nome: response.data.data.nome,                            
            };

            if ( response.status === 201 ){
                toast.success("Cadastro realizado com sucesso!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                
                if (response.data.data.id) {
                    handleOnClick(response.data.data.id);
                }

                return true;
            }
        })
        .catch(function (error) {
            let message_error = "";
            
            try {    
                message_error = typeof error.response.data.error != "undefined" ? error.response.data.error : "";
            }catch(er){};           

            toast.error("Erro no cadastro! " + message_error, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });

        return false;
    }

    handleEditProduct = async(id, param) => {
        
        await api.put('/admin/produtos/' + id, param)
        .then(function (response) {
            let dados = {
                nome: response.data.data.nome,                            
            };
            toast.success("Cadastro realizado com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            return true;
        })
        .catch(function (error) {
            let message_error = "";
            
            try {    
                message_error = typeof error.response.data.error != "undefined" ? error.response.data.error : "";
            }catch(er){};           

            toast.error("Erro no cadastro! " + message_error, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });

        return false;
    }

    handleDeleteImage = async(id) => {
        
        await api.delete('/admin/produtos/fotos/' + id)
        .then(function (response) {
            toast.success("Atualização realizada com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
        .catch(function (error) {
            let message_error = "";
            
            try {    
                message_error = typeof error.response.data.error != "undefined" ? error.response.data.error : "";
            }catch(er){};           

            toast.error("Erro na atualização! " + message_error, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });

        this.handleOnClick(this.state.produto.id);

        return false;
    }

    handleDeleteProduto = async() => {
        
        await api.delete('/admin/produtos/' + this.state.produto.id)
        .then(function (response) {
            toast.success("Atualização realizada com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
        .catch(function (error) {
            let message_error = "";
            
            try {    
                message_error = typeof error.response.data.error != "undefined" ? error.response.data.error : "";
            }catch(er){};           

            toast.error("Erro na atualização! " + message_error, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });

        this.handleOnClick(this.state.produto.id);
    }

    handleAddProduto = () => {
        this.handleSelectOptions();
        this.setState({showModal:true}); 
    };
  
    handleModalClose = () => {
        this.setState({showModal:false, produto: {}})
    };

    handleModalShow = () => {
      this.setState({showModal:true})
      this.handleSelectOptions();
    };

    render() {
        const { data, sizePerPage, page } = this.state;

        const columns = [
         {
            dataField: 'nome',
            text: 'Nome',
            sort: true,
            filter_form: {
                name: 'nome',
                type: "InputFilter"
            }
          }, {
            dataField: 'c',
            text: 'Custo',
            formatter: "coin",
            sort: true
          }, {
            dataField: 'preco_atacado',
            text: 'Preço de Atacado',
            formatter: "coin",
            sort: true
          }, {
            dataField: 'preco_varejo',
            text: 'Preço de Varejo',
            formatter: "coin",
            sort: true
          }, {
            dataField: 'produtos_departamentos_nome',
            text: 'Departamento',
            sort: true,
            filter_form: {
                type: "SelectFilter",
                name: "produtos_departamentos_id",
                valueSelected: null,
                onChange: this.handleAfterDepartamentos,
                options: this.state.selectOptions.departamentos
            }
          }, {
            dataField: 'produtos_marcas_nome',
            text: 'Marca',
            sort: true,
            filter_form: {
                type: "SelectFilter",
                name: "produtos_marcas_id",
                valueSelected: null,
                onChange: () => {},
                options: this.state.selectOptions.marcas
            }
          }, {
            dataField: 'produtos_categorias_nome',
            text: 'Categoria',
            sort: true,
            filter_form: {
                type: "SelectFilter",
                name: "produtos_categorias_id",
                valueSelected: null,
                onChange: () => {},
                options: this.state.selectOptions.categorias
            }
          },{
            dataField: 'disponivel_venda',
            text: 'Disponível Venda',
            formatter: "bool",
            sort: true,
            filter_form: {
                name: 'disponivel_venda',
                type: "SelectFilter",
                valueSelected: null,
                onChange: () => {},
                options: [
                    {value: true, label: "Sim"},
                    {value: false, label: "Não"}
                ]
            }
          },{
            dataField: 'cancelado',
            text: 'Cancelado',
            formatter: "bool",
            sort: true
          }];

        return (
            <Container className="content-body">            
                <Row>
                    <Col xs={10} md={2}>
                        <AdminNavigation props={this.props}/>
                    </Col>
                    <Col xs={10} md={10}>
                        <div className="table-actions-box">
                            <Button variant="primary" onClick={this.handleAddProduto}>
                                <FontAwesomeIcon icon={faPlus} /> Adicionar Produto
                            </Button>

                            <Button variant="secundary" onClick={this.handleShowFilter}>
                                <FontAwesomeIcon icon={faFilter} /> Filtro
                            </Button>
                        </div>
                        {/* <AdminModalAddProduct selectOptions={this.state.selectOptions} handleSelectOptions={this.handleSelectOptions} />                         */}
                        <AdminModalCRUDProduct 
                            selectOptions={this.state.selectOptions} 
                            handleSelectOptions={this.handleSelectOptions} 
                            show={this.state.showModal} 
                            handleClose={this.handleModalClose} 
                            produto={this.state.produto}
                            handleAddProduct={this.handleAddProduct}
                            handleEditProduct={this.handleEditProduct}
                            handleAfterChange={this.handleOnClick}
                            handleAfterDepartamentos={this.handleAfterDepartamentos}
                            handleAfterCategorias={this.handleAfterCategorias}
                            handleDeleteImage={this.handleDeleteImage}
                            handleDeleteProduto={this.handleDeleteProduto}
                         />
                        {this.state.showFilter && 
                            <BasicTableFilter
                                columns={columns}
                                handleSubmit={this.handleSubmitFilter}
                            />
                        }
                        <BasicTable 
                            data={ this.state.produtos }
                            page={ page }
                            sizePerPage={ sizePerPage }
                            totalSize={ this.state.total }
                            onTableChange={ this.handleTableChange }
                            columns={columns}
                            title="Produtos"
                            handleOnClickRow={this.handleOnClick}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
}