import React,  {useState, useEffect} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import './styles.css';
import { Modal, Button, Tabs, Tab } from '../../../node_modules/react-bootstrap';

const ModalDeleteProduto = (props) => {
    return (
    <Modal show={props.show} dialogClassName="modal-container">
      <Modal.Header closeButton>
          <Modal.Title>Excluir Produto?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <div >
            <Button variant="danger" type="submit" onClick={()=>{
              props.handleDeleteProduto();
              props.handleCloseModal();
            }}> Confirmar Exclusão </Button>
          </div>        
      </Modal.Body>
    </Modal>
    )
  }

const AdminFormCRUDProduct = (props) => {
    const [selectedOptionCategoria, setSelectedOptionCategoria] = useState(null);
    const [selectedOptionMarca, setSelectedOptionMarca] = useState(null);
    const [selectedOptionDepartamento, setSelectedOptionDepartamento] = useState(null);
    const [selectedOptionSubCategoria, setSelectedOptionSubCategoria] = useState(null);

    const [selectOptionCategorias, setSelectOptionCategorias] = useState(null);
    const [selectOptionMarcas, setSelectOptionMarcas] = useState(null);
    const [selectOptionSubCategorias, setSelectOptionSubCategorias] = useState(null);
    const [selectOptionDepartamentos, setSelectOptionDepartamentos] = useState(null);

    const [toEdit, setToEdit] = useState(false);
    const [show, setShow] = useState(false);

    const handleCloseModal = () => {
        setShow(false);
        props.handleCloseMainModal();
    }

    useEffect(()=>{
        setSelectedOptionCategoria({
            value: props.produto.produtos_categorias_id,
            label: props.produto.produtos_categorias_nome
        });
        setSelectedOptionMarca({
            value: props.produto.produtos_marcas_id,
            label: props.produto.produtos_marcas_nome 
        });
        setSelectedOptionDepartamento({
            value: props.produto.produtos_departamentos_id,
            label: props.produto.produtos_departamentos_nome 
        });
        setSelectedOptionSubCategoria({
            value: props.produto.produtos_subcategorias_id,
            label: props.produto.produtos_subcategorias_nome 
        })
    }, [props.produto]);

    useEffect(()=>{
        setSelectOptionCategorias(props.selectOptions.categorias);
    }, [props.selectOptions.categorias]);

    useEffect(()=>{
        setSelectOptionMarcas(props.selectOptions.marcas);
    }, [props.selectOptions.marcas]);

    useEffect(()=>{
        setSelectOptionDepartamentos(props.selectOptions.departamentos);
    }, [props.selectOptions.departamentos]);

    useEffect(()=>{
        setSelectOptionSubCategorias(props.selectOptions.subCategorias);
    }, [props.selectOptions.subCategorias]);


    const onChangeDepartamento = (selected) => {
        setSelectedOptionDepartamento(selected);
        props.handleAfterDepartamentos(selected.value);
    }

    const onChangeCategoria = (selected) => {
        setSelectedOptionCategoria(selected);
        props.handleAfterCategorias(selected.value);
    }

    

    return (
        <div>
                <Formik
                enableReinitialize 
                initialValues={props.produto}
                validate={values => {
                    const errors = {};
                    if (!values.nome) {
                        errors.nome = 'Nome do produto é obrigatório!';
                    } 
                    if ( !values.descricao ) {
                        errors.descricao = 'Descrição do produto é obrigatório!';
                    }
                    if ( !values.preco_varejo ) {
                        errors.preco_varejo = 'Preço de varejo é obrigatório!';
                    }
                    if ( !values.preco_atacado ) {
                        errors.preco_atacado = 'Preço de atacado é obrigatório!';
                    }
                    if ( !values.c ) {
                        errors.c = 'Custo é obrigatório!';
                    }
                    return errors;
                }}
                onSubmit={ (values, {setSubmitting, setErrors, setStatus, resetForm}) => {
                    if(props.produto.id){
                        let param = {
                            produto :{                              
                            }
                        };
                        
                        if (values.nome != props.produto.nome){
                            param.produto.nome = values.nome;
                        }
                        if (values.descricao != props.produto.descricao ){
                            param.produto.descricao = values.descricao;
                        }
                        if (values.c != props.produto.c){
                            param.produto.valor_custo = parseFloat(values.c);
                        }
                        if (values.preco_atacado != props.produto.preco_atacado){
                            param.produto.preco_atacado = parseFloat(values.preco_atacado);
                        }
                        if (values.preco_varejo != props.produto.preco_varejo){
                            param.produto.preco_varejo = parseFloat(values.preco_varejo);
                        }
                        if (values.disponivel_venda != props.produto.disponivel_venda){
                            param.produto.disponivel_venda = values.disponivel_venda;
                        }
                        if (values.cancelado != props.produto.cancelado){
                            param.produto.cancelado = values.cancelado;
                        }
                        if (selectedOptionCategoria.value != props.produto.produtos_categorias_id){
                            param.produto.produtos_categorias_id = selectedOptionCategoria.value;
                        }
                        if (selectedOptionMarca.value != props.produto.produtos_marcas_id){
                            param.produto.produtos_marcas_id = selectedOptionMarca.value;
                        }
                        if (values.produtos_departamentos_id != props.produto.produtos_departamentos_id){
                            param.produto.produtos_departamentos_id = values.produtos_departamentos_id;
                        }
                        if (selectedOptionSubCategoria.value != props.produto.produtos_subcategorias_id){
                            param.produto.produtos_subcategorias_id = selectedOptionSubCategoria.value;
                        }
                        setSubmitting(false);   
                        if ( props.handleEditProduct(props.produto.id, param) ){
                            props.handleAfterChange(props.produto.id);
                            setToEdit(false);
                        }
                        setSubmitting(false);
                    }
                    else{
                        let param = {
                            produto :{
                                nome: values.nome,
                                descricao: values.descricao,
                                valor_custo: values.c,
                                preco_atacado: values.preco_atacado,
                                preco_varejo: values.preco_varejo,
                                produtos_categorias_id: selectedOptionCategoria.value,
                                produtos_marcas_id: selectedOptionMarca.value,
                                produtos_subcategorias_id: selectedOptionSubCategoria.value,                                
                            }
                        };
                        setSubmitting(false);   
                        if ( props.handleAddProduct(param) ){
                            resetForm({});
                        }                       
                        setSubmitting(false);                           
                    }
                    setSubmitting(false);
                }}
                >
                {({ isSubmitting }) => (
                    <Form className="form-padrao">
                        <label htmlFor="nome">Nome</label>
                        <Field type="text" name="nome" placeholder="Nome do produto" disabled={props.produto.id && !toEdit} />
                        <ErrorMessage name="nome" component="div" className="error-message"/>

                        <label htmlFor="descricao">Descrição</label>
                        <Field type="textarea" name="descricao" placeholder="Descrição detalhada do produto" disabled={props.produto.id && !toEdit} />
                        <ErrorMessage name="descricao" component="div" className="error-message"/>
                    
                        <label htmlFor="c">Valor de Custo</label>
                        <Field type="numeric" name="c" placeholder="00.00" disabled={props.produto.id && !toEdit} />
                        <ErrorMessage name="c" component="div" className="error-message"/>

                        <label htmlFor="preco_atacado">Preço de Atacado</label>
                        <Field type="numeric" name="preco_atacado" placeholder="00.00" disabled={props.produto.id && !toEdit} />
                        <ErrorMessage name="preco_atacado" component="div" className="error-message"/>

                        <label htmlFor="preco_varejo">Preço de Varejo</label>
                        <Field type="numeric" name="preco_varejo" placeholder="00.00" disabled={props.produto.id && !toEdit} />
                        <ErrorMessage name="preco_varejo" component="div" className="error-message"/>
                        
                        <label htmlFor="produtos_departamentos_id">Departamento</label>                        
                        <Select
                            name="produtos_departamentos_id"
                            value={selectedOptionDepartamento}
                            onChange={onChangeDepartamento}
                            options={selectOptionDepartamentos}
                            className="select-box"
                            isSearchable={props.produto.id && !toEdit}
                            isDisabled={props.produto.id && !toEdit}
                        />
                        
                        <label htmlFor="produtos_categorias_id">Categoria</label>
                        <Select
                            name="produtos_categorias_id"
                            value={selectedOptionCategoria}
                            onChange={onChangeCategoria}
                            options={selectOptionCategorias}
                            className="select-box"
                            isSearchable={props.produto.id && !toEdit}
                            isDisabled={props.produto.id && !toEdit}
                        />

                        <label htmlFor="produtos_subcategorias_id">Sub Categoria</label>
                        <Select
                            name="produtos_subcategorias_id"
                            value={selectedOptionSubCategoria}
                            onChange={setSelectedOptionSubCategoria}
                            options={selectOptionSubCategorias}
                            className="select-box"
                            isSearchable={props.produto.id && !toEdit}
                            isDisabled={props.produto.id && !toEdit}
                        />

                        <label htmlFor="produtos_marcas_id">Marca</label>
                        <Select
                            name="produtos_marcas_id"
                            value={selectedOptionMarca}
                            onChange={setSelectedOptionMarca}
                            options={selectOptionMarcas}
                            className="select-box"
                            isSearchable={props.produto.id && !toEdit}
                            isDisabled={props.produto.id && !toEdit}
                        />

                        {/* Permite alterar cancelado e visivel para venda somente na edição */}
                        {(props.produto.id) &&
                            <>
                            <div className="form-check-box">
                                <label htmlFor="disponivel_venda">Disponível Venda</label>
                                <Field
                                        name="disponivel_venda"
                                        render={({ field, form }) => {
                                        return (
                                            <input
                                            type="checkbox"
                                            id="disponivel_venda"
                                            className=""
                                            disabled={props.produto.id && !toEdit}
                                            checked={field.value}
                                            {...field}
                                            />
                                        );
                                        }}
                                    />
                            </div>

                            <div className="form-check-box">
                                <label htmlFor="cancelado">Cancelado</label>
                                <Field
                                        name="cancelado"
                                        render={({ field, form }) => {
                                        return (
                                            <input
                                            type="checkbox"
                                            id="cancelado"
                                            className=""
                                            disabled={props.produto.id && !toEdit}
                                            checked={field.value}
                                            {...field}
                                            />
                                        );
                                        }}
                                    />
                            </div>
                            </>
                        }

                        
                        <div className="submit-box">                            
                            {(props.produto.id && toEdit==true) &&
                                <div>
                                    <button className="cancel-color" type="button" onClick={() => {setToEdit(false)}}>
                                        Cancelar
                                    </button> 
                                    <button type="submit" disabled={isSubmitting || selectedOptionCategoria==null || selectedOptionMarca==null}>
                                        Confirmar
                                    </button> 
                                </div>
                            }
                            
                            {(props.produto.id && toEdit==false) &&
                                <div>
                                    <Button variant="danger" className="infor-color-danger" type="button" onClick={() => {setShow(true)}}>
                                        Excluir
                                    </Button>
                                    <button className="infor-color" type="button" onClick={() => {setToEdit(true)}}>
                                        Editar
                                    </button> 
                                </div>
                            }        
                            {!props.produto.id &&
                                <button type="submit" disabled={isSubmitting || selectedOptionCategoria==null || selectedOptionMarca==null}>
                                    Cadastrar
                                </button>
                            }
                        </div>
                    </Form>
                )}
                </Formik>
                <ModalDeleteProduto show={show} handleCloseModal={handleCloseModal} handleDeleteProduto={props.handleDeleteProduto} />
                <ToastContainer />
        </div>
    );
}

export default AdminFormCRUDProduct;