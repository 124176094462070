import React, { Component } from 'react';
import { Container, Row, Col, Button } from '../../../../node_modules/react-bootstrap';
import AdminNavigation from '../../../components/adminNavigation';
import api from '../../../services/api';
import BasicTable from '../../../components/basicTable';
import AdminModalCRUDUser from '../../../components/adminModalCRUDUser';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFilter } from '@fortawesome/free-solid-svg-icons';
import "./styles.css";
import BasicTableFilter from "../../../components/basicTableFilter";

export default class AdminUsers extends Component {
    constructor (props) {
        super(props);
        this.state = {
            page: 1,            
            usuarios : [],
            sizePerPage: 15,
            total: 0,
            showModal: false,
            usuario: {},
            showFilter: false
        }
    };    

    componentDidMount() {        
        this.buscarUsuarios();
    }

    handleShowFilter = () => {
        this.setState({ showFilter: !this.state.showFilter });
    }

    handleOnClick = (id) => {
        this.buscarUsuario(id);
        this.setState({showModal:true}); 
    };    

    buscarUsuarios = async (page, size, sortOrder, sortField, filter) => {
        let complement = "";
        let usuarios = [];
        let total = 0;

        complement += (typeof(page) != "undefined") ? "page=" + page : "";
        complement += (typeof(size) != "undefined") ? "&size=" + size : "";
        complement += (typeof(sortOrder) != "undefined") ? "&sortOrder=" + sortOrder : "";
        complement += (typeof(sortField) != "undefined") ? "&sortField=" + sortField : "";
        
        if ( typeof(filter) != "undefined" ) {
            complement += "&filter=" + encodeURIComponent(JSON.stringify(filter));
        }
        
        await api.get('/admin/usuarios?'+complement)
        .then(function (response) {
            usuarios = response.data.data; 
            total = response.data.total;                        
        });
        
        this.setState({ usuarios: usuarios, total: total });        
    }
  
    buscarUsuario = async (id) => {
        let usuario={};

        await api.get('/admin/usuarios/'+id)
        .then(function (response) {
            usuario = response.data.data;           
        });
        
        this.setState({ usuario: usuario });
    }
    
    handleTableChange = (type, params) => {
        const { page, sizePerPage, sortOrder, sortField } = params;
    
        this.buscarUsuarios(page, sizePerPage, sortOrder, sortField);
        this.setState(() => ({
            page,
            sizePerPage,
        }));        
    };

    handleSubmitFilter = (params) => {
        let page = 0;
        let sizePerPage = 15; 
        this.buscarUsuarios(page, sizePerPage, undefined, undefined, params);
        this.setState(() => ({
            page,
            sizePerPage,
        }));
    }

    handleAddUser = async(param, handleOnClick=this.handleOnClick) => {
        
        await api.post('/admin/usuarios', param)
        .then(function (response) {
            let dados = {
                nome: response.data.data.nome,                            
            };

            if ( response.status === 201 ){
                toast.success("Cadastro realizado com sucesso!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                
                if (response.data.data.id) {
                    handleOnClick(response.data.data.id);
                }

                return true;
            }
        })
        .catch(function (error) {
            let message_error = "";
            
            try {    
                message_error = typeof error.response.data.error != "undefined" ? error.response.data.error : "";
            }catch(er){};           

            toast.error("Erro no cadastro! " + message_error, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });

        return false;
    }

    handleEditUser = async(id, param) => {
        
        await api.put('/admin/usuarios/' + id, param)
        .then(function (response) {
            let dados = {
                nome: response.data.data.nome,                            
            };
            toast.success("Cadastro realizado com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            return true;
        })
        .catch(function (error) {
            let message_error = "";
            
            try {    
                message_error = typeof error.response.data.error != "undefined" ? error.response.data.error : "";
            }catch(er){};           

            toast.error("Erro no cadastro! " + message_error, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });

        return false;
    }


    handleClickAddUsuario = () => {
        this.setState({showModal:true}); 
    };
  
    handleModalClose = () => {
        this.setState({showModal:false, usuario: {}})
    };

    handleModalShow = () => {
      this.setState({showModal:true})      
    };

    render() {
        const { data, sizePerPage, page } = this.state;

        const columns = [
         {
            dataField: 'nome',
            text: 'Nome',
            sort: true,
            filter_form: {
                name: 'nome',
                type: "InputFilter"
            }
          },
          {
            dataField: 'atendimento_id',
            text: 'Atendimento',
            sort: true,
            filter_form: {
                name: 'atendimento_id',
                type: "InputFilter"
            }
          },
          {
            dataField: 'celular',
            text: 'Whats',
            sort: true,
            filter_form: {
                name: 'celular',
                type: "InputFilter"
            },
            formatter: "fone"
          },
          {
            dataField: 'ativo',
            text: 'ativo',
            formatter: "bool",
            sort: true
          },
          {
            dataField: 'vendedor',
            text: 'vendedor',
            formatter: "bool",
            sort: true
          }
        ];

        return (
            <Container className="content-body">            
                <Row>
                    <Col xs={10} md={2}>
                        <AdminNavigation props={this.props}/>
                    </Col>
                    <Col xs={10} md={10}>
                        <div className="table-actions-box">
                            <Button variant="primary" onClick={this.handleClickAddUsuario}>
                                <FontAwesomeIcon icon={faPlus} /> Adicionar Usuário
                            </Button>

                            <Button variant="secundary" onClick={this.handleShowFilter}>
                                <FontAwesomeIcon icon={faFilter} /> Filtro
                            </Button>
                        </div>

                        <AdminModalCRUDUser 
                            show={this.state.showModal} 
                            handleClose={this.handleModalClose} 
                            usuario={this.state.usuario}
                            handleAddUser={this.handleAddUser}
                            handleEditUser={this.handleEditUser}
                            handleAfterChange={this.handleOnClick}
                         />
                        {this.state.showFilter && 
                            <BasicTableFilter
                                columns={columns}
                                handleSubmit={this.handleSubmitFilter}
                            />
                        }
                        <BasicTable 
                            data={ this.state.usuarios }
                            page={ page }
                            sizePerPage={ sizePerPage }
                            totalSize={ this.state.total }
                            onTableChange={ this.handleTableChange }
                            columns={columns}
                            title="Usuários"
                            handleOnClickRow={this.handleOnClick}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
}