import React, {Component} from 'react';
import { Container } from 'react-bootstrap';
import api from '../../services/api';
import FormatterCoin from '../../components/formatterCoin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import FormatterDataPadrao from '../../components/formatterDataPadrao';
import { Link } from "react-router-dom";


class MyShoppingsPage  extends Component{
    constructor (props) {
        super(props);
        this.state = {
            pedidos: []            
        }
    };

    async componentDidMount() {
        const { match: { params } } = this.props;         
        this.buscarPedidos(params.id);    
    }    

    buscarPedidos = async (usuario_id) => {
        let pedidos={};

        await api.get('/usuario/'+ usuario_id  +'/pedidos/')
            .then(async (response) => {
                pedidos = response.data.data;                                 
            });    

        this.setState({ pedidos: pedidos });        
    }

    render () {
    return (
        <Container>
            <table className="table-shopping-cart">
                <tr>
                    <th>Estado</th>
                    <th>Data</th>
                    <th>Qtd de Itens</th>
                    <th>Valor Total</th>                                        
                    <th>Ações</th>
                </tr>
                {this.state.pedidos && this.state.pedidos.map( (ped, i) =>{ 
                    return (
                        <tr>
                            <td className="td-normal">
                                {ped.estado}
                            </td>
                            <td className="td-normal">                                
                                <FormatterDataPadrao date={ped.inserted_at} />
                            </td>
                            <td className="td-normal">
                                {ped.qtd_itens}
                            </td>                        
                            <td className="td-normal">
                                <FormatterCoin valor={ped.valor_total} />
                            </td>     
                            
                            <td className="td-normal"><Link to={"/pedido/"+ped.id}><FontAwesomeIcon icon={faExternalLinkAlt} /></Link></td>                        
                            
                        </tr>
                    )
                })}
                </table>                                     
        </Container>
    );
    }
}

export default MyShoppingsPage;