import React, {Component} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './styles.css';
import FormatterDataPadrao from '../formatterDataPadrao';

const sizePerPageOptionRenderer = ({
    text,
    page,
    onSizePerPageChange
  }) => (
    <li
      key={ text }
      role="presentation"
      className="dropdown-item"
    >
      <a
        href="#"
        tabIndex="-1"
        role="menuitem"
        data-page={ page }
        onMouseDown={ (e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        } }
        style={ { color: 'blue' } }
      >
        { text }
      </a>
    </li>
  );
  
  const options = {
    sizePerPageOptionRenderer
  };


const coin = (cell, row) => {
  let formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });

  return (
    <span>{formatter.format(parseFloat(cell).toFixed(2))}</span>
  );
}

const bool = (cell, row) => {
  return (
    <span>{cell ? "Sim" : "Não"}</span>
  );
}

const datef = (cell, row) =>{  
  return (
    <FormatterDataPadrao date={cell} />
  )
}

const fone = (cell, row) => {
  cell=cell.replace(/[^\d]+/g,'');             //Remove tudo o que não é dígito
  if (cell.length > 3){
    cell=cell.replace(/(\d{2})(\d{5,})/g, (regex, arg1, arg2) => {
          arg2=arg2.replace(/(\d{5})(\d)/g, (reg, param1, param2) => { return param1+ '-' + param2});
          return '(' + arg1 + ') ' + arg2
      }); //Coloca parênteses em volta dos dois primeiros dígitos
  }
return (<span>{cell}</span>);
}

const processColumnsFormatter = (columns) => {
  
  const new_columns = columns.map(function(col) {
    switch(col.formatter) {
      case "coin" :
        col.formatter = coin;
        break;
      case "bool" :
        col.formatter = bool;
        break;
      case "fone" :
        col.formatter = fone;
        break;
      case "date" :
        col.formatter = datef;
        break;
    };

    return col;
  });

  return new_columns;
}

const BasicTable = ({ data, page, sizePerPage, onTableChange, totalSize, columns, title, handleOnClickRow }) => {

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        handleOnClickRow(row.id);
      }
    };

    const columns_formatted = processColumnsFormatter(columns);

    return (
      <div>
      <PaginationProvider
        pagination={
          paginationFactory({
            custom: true,
            page,
            sizePerPage,
            totalSize,
            showTotal: true,
            hideSizePerPage: true,
            sizePerPageList: [ {
              text: '10', value: 10
            }, {
              text: '15', value: 15
            }, {
              text: '50', value: 50
            } ],
          })
        }
      >
        {
          ({
            paginationProps,
            paginationTableProps
          }) => (
            <div className="basic-table-container">
              <h2>{title}</h2>
              <BootstrapTable
                bootstrap4
                noDataIndication={()=>{return <p>Sem Produtos</p>}} 
                hover
                remote
                keyField="id"
                data={ data }
                columns={ columns_formatted }
                rowEvents={ rowEvents }
                onTableChange={ onTableChange }
                { ...paginationTableProps }
                // A numeric array is also available: [5, 10]. the purpose of above example is custom the text
              //   pagination={ paginationFactory(options) }
              />
              <PaginationListStandalone
                  { ...paginationProps }
                  // pagination={paginationFactory(options)}
                />
            </div>
          )
        }
      </PaginationProvider>
    </div>
    );
};

export default BasicTable;