import React, {useState, useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faShoppingCart, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

const AdminHeader = (props) => {

    return (        
        <header id="main-header">
            <Container>
                <Row className="main-header-top">
                    <Col xs={12} md={4} className="logo-div">                    
                        <h1><Link to="/admin/">INFOR HOUSE ADMIN</Link></h1>     
                    </Col>
                    <Col xs={12} md={4}>
                        <div className="search-bar"> 
                            <form>              
                                <input type="text" 
                                    id="strBusca" onBlur={()=>{console.log("ok")}} name="strBusca" placeholder="O que você está procurando?" spellCheck="false" autoComplete="off" dir="auto" nm-autocomplete="off"
                                 />
                                <button type="button" value="Buscar" id="btnOK" className="search-button">
                                    <FontAwesomeIcon icon={faSearch} />    
                                </button>
                            </form>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className="main-header-icons">
                        <div className="main-header-user">                            
                            <FontAwesomeIcon icon={faUser} />
                            <span>
                                <Link to="/usuario">{props.usuario ? props.usuario.nome : 'Visitante'}</Link><br/>
                                <Link to={"/login"}>Sair</Link>
                            </span>
                        </div>
                    </Col>
                </Row>                
            </Container>
        </header>        
    )
}

export default AdminHeader;