import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faArrowsAltV, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import FormatterCoin from '../../components/formatterCoin';
import env from "../../config";
import './styles.css';

const ShoppingCartProductsTable = (props) => {
    const [pedido, setPedido] = useState([]);
    const [usuario, setUsuario] = useState({});

    useEffect(()=>{        
        setPedido(props.pedido);
    }, [props.pedido]);

    useEffect(()=>{        
        setUsuario(props.usuario);
    }, [props.usuario]);

    return (
        <table className="table-shopping-cart">
        <tr>
            <th>Foto</th>
            <th>Produto</th>
            <th>Valor</th>
            <th>Desconto</th>
            <th>QTD</th>
            <th>Sub. Total</th>
            <th>Ações</th>
        </tr>
        {pedido.produtos && pedido.produtos.map( (prod, i) =>{ 
            return (
            <tr>
                <td className="td-normal">
                    {prod.produtos_fotos &&
                        <img src={env.url_files + prod.produtos_fotos[0].id +"-"+ prod.produtos_fotos[0].filename } />
                    }
                </td>
                <td className="td-nome">                                
                    {prod.nome}
                </td>
                {pedido.atacado &&
                    <td className="td-normal venda-preco-atacado">A  <FormatterCoin valor={prod.preco_atacado} /> </td>
                }
                {!pedido.atacado &&
                    <td className="td-normal venda-preco-varejo">V  <FormatterCoin valor={prod.preco_varejo} /> </td>
                }                
                <td className="td-normal"><FormatterCoin valor={prod.desconto} /></td>
                
                {pedido.estado == "Carrinho de Compras" &&
                    <td className="td-normal td-alterar" onClick={()=>props.handleOnClickQtd(prod)}>{prod.quantidade} <FontAwesomeIcon icon={faArrowsAltV} /> </td>
                }
                {pedido.estado != "Carrinho de Compras" &&
                    <td className="td-normal">{prod.quantidade}</td>
                }
                <td className="td-normal"><FormatterCoin valor={prod.sub_total} /></td>     
                {pedido.estado == "Carrinho de Compras" &&
                    <td className="td-normal td-excluir">
                        <FontAwesomeIcon icon={faTrash} onClick={()=>props.handleOnClickExcluir(prod)}/>
                        {usuario.admin &&
                            <span className="icon-desconto"> <FontAwesomeIcon icon={faArrowDown} onClick={()=>props.handleOnClickDesconto(prod)} /> </span>
                        }
                    </td>                        
                }
                {pedido.estado != "Carrinho de Compras" &&
                    <td className="td-normal td-excluir">-</td>                        
                }
            </tr>
            )
        })}
        </table>   
    )
};

export default ShoppingCartProductsTable;
            