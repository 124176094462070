import React, {useEffect} from 'react';
import { Route } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import AdminSalerMain from '../adminSalerMain';



const AdminSalerRouter = (props) => {
    let history = useHistory();

    useEffect(()=>{        
        setTimeout(()=>{
            if ( props.usuario && (props.usuario.error || props.usuario.vendedor == false) ) {        
                history.push("/login?error=authVendedor");
            }
        }, 1000);        
    }, [props.usuario]);

    return (
        <div>
            {/* <AdminHeader usuario={props.usuario} /> */}
            {/* note the addition of the exact property here */}
            <Route exact path={props.match.url}>
                <AdminSalerMain pedido_carrinho={props.pedido_carrinho} usuario={props.usuario} buscarPedidoCarrinho={props.buscarPedidoCarrinho} />
            </Route>
            {/* <Route path={props.match.url + "/historico"} component={AdminUsers} />            */}
        </div>
    );
};

export default AdminSalerRouter;

