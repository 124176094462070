import React from 'react';

const FormatterCoin = (props) => {
  
    let formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });
  
    return (
      <span>{formatter.format(parseFloat(props.valor? props.valor : 0).toFixed(2))}</span>
    );
}

export default FormatterCoin;