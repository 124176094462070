import React from 'react';

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import ReactImageMagnify from "react-image-magnify";
import "./styles.css";

const SlideDetail = (props) => {

    const myRenderItem = (props) => {
        return <ReactImageMagnify
        {...{
          smallImage: {
            alt: props.description,
            isFluidWidth: true,
            src: props.original
          },
          largeImage: {
            src: props.original,
            width: 640,
            height: 480
          },
          enlargedImagePortalId: "zoom-box"
        }}
      />;
    }

    const properties = {
        thumbnailPosition: "left",
        useBrowserFullscreen: false,
        showPlayButton: false,
        renderItem: myRenderItem,
        showNav:false,
        showFullscreenButton: false,
        items: props.fotos
    };

    return (
        <div>
            <ImageGallery {...properties} />
            <div id="zoom-box" />
        </div>
    );
}

export default SlideDetail;

