import React, {Component, useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import './styles.css';
import { Container } from '../../../node_modules/react-bootstrap';
import API from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';

function MensagemSucesso(props) {
    if (!props.visivel) {
        return null
    }

    return (
        <div className="message-success form-register">              
            <p>Seu cadastro foi realizado com sucesso.
            <br/> Enviamos um SMS com a confirmação da sua senha temporária.
            </p>
            <Link to={"/login"}>Realizar Login</Link>
        </div>
    )
}

function replaceTel(param){        
    param=param.replace(/[^\d]+/g,'');             //Remove tudo o que não é dígito
    if (param.length > 3){
        param=param.replace(/(\d{2})(\d{5,})/g, (regex, arg1, arg2) => {
            arg2=arg2.replace(/(\d{5})(\d)/g, (reg, param1, param2) => { return param1+ '-' + param2});
            return '(' + arg1 + ') ' + arg2
        }); //Coloca parênteses em volta dos dois primeiros dígitos
    }
    return param;
}

const options = [
    { value: 'ce', label: 'Ceara' },
    { value: 'pb', label: 'Paraiba' },
    { value: 'pe', label: 'Pernambuco' },
  ];

function FormRegisterUsuario(props) {

    const [selectedOptionEstado, setSelectedOptionEstado] = useState(null);
    const [selectedOptionCidade, setSelectedOptionCidade] = useState(null);
    const [optionsCidade, setOptionsCidade] = useState([ {value: null, label: 'Selecione o estado'}]);
    const [celularForm, setCelularForm] = useState("");

    if (!props.visivel) {
        return null
    }

    let handleChangeEstado = estado => {
        setSelectedOptionEstado(estado);

        API.get('/cidades?uf='+estado.value)
            .then(function (response) {
               var cidades = response.data.data.map(function(cidade) {
                return {value: cidade.id, label: cidade.nome};
              });

              setOptionsCidade(cidades);
            })
    };

    let handleChangeCidade = cidade => {
        setSelectedOptionCidade(cidade);
    };
    
    return (
        <div>
                <Formik
                initialValues={{ nome: '', celular: '' }}
                validate={values => {
                    const errors = {};
                    if (!celularForm) {
                        errors.celular = 'Seu contato do Whatsapp é obrigatório!';
                    } 
                    if ( !values.nome ) {
                        errors.nome = 'O nome do proprietário é obrigatório!';
                    }
                    return errors;
                }}
                onSubmit={ (values, {setSubmitting, setErrors, setStatus, resetForm}) => {
                    let param = {
                        usuario :{
                            nome: values.nome,
                            celular: celularForm.replace(/[^\d]+/g,'')
                        }
                    }

                    API.post('/usuarios', param)
                    .then(function (response) {
                        // handle success
                        setSubmitting(false);

                        let dados = {
                            nome : response.data.data.nome,
                            formError: false,
                            formVisivel: false
                        };

                        if ( response.status === 201 ){
                            props.handleResponseData(dados);
                            resetForm({});
                        }
                    })
                    .catch(function (error) {
                        let message_error = ""
                        try{
                            message_error = typeof error.response.data.error != "undefined" ? error.response.data.error : "";
                        }catch(e){};
                        setSubmitting(false);
                        let dados = {
                            nome : '',
                            formError: true,
                            formVisivel: true
                        };        
                        props.handleResponseData(dados);                

                        toast.error("Erro no cadastro! " + message_error, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    });
                }}
                >
                {({ isSubmitting }) => (
                    <Form className="form-register">
                        <label htmlFor="nome">Proprietário/Responsável</label>
                        <Field type="nome" name="nome" placeholder="Nome do proprietário ou responsável" />
                        <ErrorMessage name="nome" component="div" className="error-message"/>

                        <label htmlFor="celular">Whatsapp(DDD+NUMERO)</label>
                        <Field type="text" name="celular" placeholder="00000000000" maxlength="15" onChange={ 
                            e => { 
                                let newValue = replaceTel(e.target.value); 
                                setCelularForm(newValue);
                            }}
                            value={celularForm}
                        />
                        <ErrorMessage name="celular" component="div" className="error-message"/>
                        
                        <div className="submit-box">
                            <button type="submit" disabled={(isSubmitting)}>
                                Cadastrar
                            </button>
                        </div>

                        <div className="link-options">                            
                            <Link to="/login">Login</Link>
                        </div>
                    </Form>
                )}
                </Formik>
                <ToastContainer />
        </div>
    );
}

export default class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nome: '',
            formError: false,
            formVisivel: true
        };
    }

    handleResponseData = (dados) => {
        this.setState( dados );
    }
    
    render() {        
        return (
            <Container className="content-body">
                <h1>Cadastro Para Atacadista</h1>
                <p className="body-register-subtitle">
                    Você têm loja física ou virtual de acessório para celular, capinha, informática e variedade?<br/>
                    Está procurando o melhor preço e um atendimento atencioso e exlusivo para sua empresa? <br/>
                    Esse espaço é para você.
                </p>
                <FormRegisterUsuario visivel={this.state.formVisivel} handleResponseData={this.handleResponseData} />
                <MensagemSucesso visivel={!this.state.formVisivel} />
            </Container>
        );
    }
}