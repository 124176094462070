import React, { Component } from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Header from './components/header';
import HeaderSimple from './components/headerSimple';
import Main from './pages/main';
import Product from './pages/product';
import Register from './pages/register';
import Login from './pages/login';
import User from './pages/user';
import AdminRouter from './pages/admin/adminRouter';
import { UsuarioSessao } from "./business/usuarioSessao";
import { PedidoCarrinhoSessao } from "./business/carrinhoSessao";
import Products from './pages/products';
import ShoppingCartPage from './pages/shoppingCart';
import MyShoppingsPage from './pages/myShoppingsPage';
import AdminSalerRouter from './pages/adminSaler/adminSalerRouter';
import MenuVenda from './components/menuVenda';

export default class Routes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usuario: null,
            pedido_carrinho: {}
        }        
    };
    
    componentDidMount() {
        this.buscarUsuario();        
    }

    buscarUsuario = async () => {
        let usuario_sessao = await UsuarioSessao();    
        this.setState({usuario: usuario_sessao});
        this.buscarPedidoCarrinho();
    }

    buscarPedidoCarrinho = async () => {
        let pedido = await PedidoCarrinhoSessao();    
        this.setState({pedido_carrinho: pedido});   
        return true;     
    }

    render () {    

        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/">
                        <Header usuario={this.state.usuario} pedido_carrinho={this.state.pedido_carrinho}/>
                        <Main/>
                        <MenuVenda usuario={this.state.usuario} />
                    </Route>
                    <Route path={["/produtos/:departamento/:categoria/:subcategoria", "/produtos/:departamento/:categoria/", "/produtos/:departamento/"]} 
                        component={({match, location}) =>{
                            return (
                                <>
                                    <Header usuario={this.state.usuario} pedido_carrinho={this.state.pedido_carrinho}/>
                                    <Products match={match} location={location} usuario={this.state.usuario} pedido_carrinho={this.state.pedido_carrinho} buscarPedidoCarrinho={this.buscarPedidoCarrinho} />
                                    <MenuVenda usuario={this.state.usuario} />
                                </>
                            )
                        }}
                    />
                    <Route path={["/produtosadmin/:departamento/:categoria/:subcategoria", "/produtosadmin/:departamento/:categoria/", "/produtosadmin/:departamento/"]} 
                        component={({match, location}) =>{
                            return (
                                <>
                                    <Header usuario={this.state.usuario} pedido_carrinho={this.state.pedido_carrinho}/>
                                    <Products match={match} location={location} usuario={this.state.usuario} pedido_carrinho={this.state.pedido_carrinho}/>
                                    <MenuVenda usuario={this.state.usuario} />
                                </>
                            )
                        }}
                    />
                    <Route path="/produto/:id"
                        component={({match, location}) =>{
                            return (
                                <>                                    
                                    <Header usuario={this.state.usuario} pedido_carrinho={this.state.pedido_carrinho} />
                                    <Product match={match} usuario={this.state.usuario} pedido_carrinho={this.state.pedido_carrinho}  buscarPedidoCarrinho={this.buscarPedidoCarrinho}/>
                                    <MenuVenda usuario={this.state.usuario} />
                                </>
                            )
                        }} />
                    <Route path="/cadastro">
                        <HeaderSimple />
                        <Register />
                        <MenuVenda usuario={this.state.usuario} />
                    </Route>
                    <Route path="/login">
                        <HeaderSimple />
                        <Login handleLogin={this.buscarUsuario} />
                        <MenuVenda usuario={this.state.usuario} />
                    </Route>
                    <Route path={["/pedido/:id/:sub", "/pedido/:id"]} component={({match, location}) =>{
                            return (
                                <>                                    
                                    <HeaderSimple />
                                    <ShoppingCartPage match={match}  usuario={this.state.usuario} />                    
                                    <MenuVenda usuario={this.state.usuario} />
                                </>
                            )
                    }} />

                    <Route path="/usuario/:id/pedidos" component={({match, location}) =>{
                            return (
                                <>                                    
                                    <HeaderSimple />
                                    <MyShoppingsPage match={match}/>          
                                    <MenuVenda usuario={this.state.usuario} />          
                                </>
                            )
                    }} />

                    <Route path="/admin" component={ ({match}) => 
                        <AdminRouter match={match} usuario={this.state.usuario} /> 
                    } />
                    <Route path="/venda" component={ ({match}) => 
                        <AdminSalerRouter match={match} usuario={this.state.usuario} pedido_carrinho={this.state.pedido_carrinho} buscarPedidoCarrinho={this.buscarPedidoCarrinho} /> 
                    } />
                    <Route path="/usuario">
                        <HeaderSimple />
                        <MenuVenda usuario={this.state.usuario} />
                        <User />                                                
                    </Route>

                </Switch>
            </BrowserRouter>
        )
    }
}
    