import React, {componentDidMount, useState, useEffect} from 'react';
import Footer from './components/footer';
import Routes from './router';
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {

  return (    
    <div className="App">
      <Routes />
      <Footer />
    </div>
  );
}

export default App;