import React from 'react';
import api from '../../services/api';

const UsuarioSessao = async () => {
    let usuario = null;
    
    await api.get('/usuarios/ativo')
        .then(function (response) {
            usuario = response.data.data;                             
        })
        .catch(function (error) {
            // handle error
            usuario = {
                error: true
            }
        })

    return usuario;
}

export {UsuarioSessao};