import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStoreAlt, faTruck, faArrowRight, faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import api from '../../services/api';
import env from "../../config";
import './styles.css';

const replaceCep = (param) => {        
    param=param.replace(/[^\d]+/g,'');             //Remove tudo o que não é dígito
    if (param.length > 3){
        param=param.replace(/(\d{5})(\d{3})/g, (regex, arg1, arg2) => {
            return arg1 + '-' + arg2
        }); //Coloca parênteses em volta dos dois primeiros dígitos
    }
    return param;
}

const ShoppingCartDelivery = (props) => {
    const [pedido, setPedido] = useState([]);
    const [step, setStep]= useState(1);
    const [cepFormated, SetCepFormated] = useState('');
    const [bairro, setBairro] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUf] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [nomeEntrega, setNomeEntrega] = useState('');
    const [enderecos, setEnderecos] = useState([]);

    useEffect(()=>{        
        setPedido(props.pedido);

        if (props.pedido && props.pedido.id && !props.pedido.core_usuarios_cliente_id){
            window.location.assign("/pedido/" +  props.pedido.id);
        }

        if(props.pedido.core_usuarios_cliente_id) {
            api.get('/usuario/' + props.pedido.core_usuarios_cliente_id + '/enderecos')
                .then( function (res) {
                    setEnderecos(res.data.data);
                })
                .catch(function (error) {
                    toast.error("Erro ao buscar endereços!", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                });           
            }
    }, [props.pedido]);

    const handleRetirada = async  (pedido_id) => {
    
        await api.put('/usuario/' + pedido.core_usuarios_cliente_id + '/pedidos/' + pedido.id + '/endereco/retirada' )
        .then( function (res) {
            window.location.assign("/pedido/" +  pedido.id);
        })
        .catch(function (error) {
            toast.error("Erro no cadastro!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });                  

        window.location.assign("/pedido/" +  pedido.id);
    }
    

    const handleEndereco = (endereco_id) => {
        api.put('/usuario/' + pedido.core_usuarios_cliente_id + '/pedidos/' + pedido.id + '/endereco/' + endereco_id)
        .then( function (res) {
            window.location.assign("/pedido/" +  pedido.id);
        })
        .catch(function (error) {
            toast.error("Erro no cadastro!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });      
    }

    return (
        <div className="shop-delivery">
            {step == 1 &&
                <div className="animate__animated  animate__fadeInLeft animate__faster">
                    <h1>Onde deseja receber a mercadoria?</h1>
                    <button className="btn-delivery" onClick={()=>{handleRetirada(pedido.id)}}><FontAwesomeIcon icon={faStoreAlt} /> Retirada na loja</button>
                    <button className="btn-delivery" onClick={()=>{setStep(2)}}><FontAwesomeIcon icon={faTruck} /> Novo Endereço </button>
                    
                    <div>
                        {enderecos && enderecos.map( (ender, i) =>{ 
                            return (
                                <button className="btn-delivery-ad" onClick={()=>{handleEndereco(ender.id)}}><FontAwesomeIcon icon={faMapMarker} /> {ender.nome_entrega} / {ender.logradouro}, {ender.numero}, {ender.bairro} / {ender.cidade} - {ender.estado}</button>
                            )
                        })}
                    </div>
                </div>
            }

            {step == 2 &&
                <div className="animate__animated  animate__fadeInLeft animate__faster">
                    <h2>Cep</h2>
                    <input type="text" onChange={ 
                            e => {                               
                                let newValue = replaceCep(e.target.value); 
                                SetCepFormated(newValue);
                                                                   
                                if (newValue.length == 9) {
                                    const cep = newValue;
                                    api.get(env.cors_local + 'https://viacep.com.br/ws/' + cep.replace(/[^\d]+/g,'') + '/json/')
                                    .then(function (response) { 
                                        setBairro(response.data.bairro);
                                        setLogradouro(response.data.logradouro);
                                        setCidade(response.data.localidade);
                                        setUf(response.data.uf);
                                    })
                                }
                            }}
                            value={cepFormated}
                            minLength='9'
                            maxLength='9'
                            placeholder='00000-000'
                    />

                    {cepFormated.length < 9 &&
                        <button className="btn-delivery" onClick={()=>{handleRetirada(pedido.id)}}> Pular <FontAwesomeIcon icon={faArrowRight} /></button>
                    }

                    {cepFormated.length == 9 &&
                        <div>
                            <Formik
                                enableReinitialize 
                                initialValues
                                validate={values => {
                                    const errors = {};
                                    // if (!values.nome) {
                                    //     errors.nome = 'Nome é obrigatório!';
                                    // }
                                    return errors;
                                }}
                                onSubmit={ (values, {setSubmitting}) => {  
                                    let param = {
                                        endereco : {
                                            logradouro: logradouro,
                                            cep: cepFormated.replace(/[^\d]+/g,''),
                                            bairro: bairro,
                                            cidade: cidade,
                                            estado: uf,
                                            numero: numero,
                                            complemento: complemento,
                                            nome_entrega: nomeEntrega
                                        }
                                    };

                                    api.post('/usuario/' + pedido.core_usuarios_cliente_id + '/enderecos', param)                                    
                                        .then(function (response) { 
                                            
                                            api.put('/usuario/' + pedido.core_usuarios_cliente_id + '/pedidos/' + pedido.id + '/endereco/' + response.data.data.id)
                                                .then( function (res) {
                                                    window.location.assign("/pedido/" +  pedido.id);
                                                })
                                                .catch(function (error) {
                                                    toast.error("Erro no cadastro!", {
                                                        position: toast.POSITION.BOTTOM_RIGHT
                                                    });
                                                });                                                                                       
                                        })
                                        .catch(function (error) {
                                            toast.error("Erro no cadastro!", {
                                                position: toast.POSITION.BOTTOM_RIGHT
                                            });
                                        });                                                                    

                                    setSubmitting(false);
                                }}
                                >
                                {({ isSubmitting }) => (
                                    <Form className="form-delivery">
                                        
                                        <label htmlFor="estado">Estado - UF </label>
                                        <Field type="text" name="estado" placeholder="CE" value={uf} onChange={ 
                                                e => {                               
                                                    setUf(e.target.value);
                                                }}/>
                                        <ErrorMessage name="estado" component="div" className="error-message"/>
                        
                                        <label htmlFor="cidade">Cidade</label>
                                        <Field type="text" name="cidade" placeholder="Juazeiro do Norte" value={cidade} onChange={ 
                                                e => {                               
                                                    setCidade(e.target.value);
                                                }}/>
                                        <ErrorMessage name="cidade" component="div" className="error-message"/>
                
                                        <label htmlFor="logradouro">Logradouro</label>
                                        <Field type="text" name="logradouro" placeholder="Rua da Paz" value={logradouro} onChange={ 
                                                e => {                               
                                                    setLogradouro(e.target.value);
                                                }}/>
                                        <ErrorMessage name="logradouro" component="div" className="error-message"/>

                                        <label htmlFor="numero">Número</label>
                                        <Field type="text" name="numero" placeholder="000" value={numero} onChange={ 
                                                e => {                               
                                                    setNumero(e.target.value);
                                                }}/>
                                        <ErrorMessage name="numero" component="div" className="error-message"/>
                
                                        <label htmlFor="bairro">Bairro</label>
                                        <Field type="text" name="bairro" placeholder="Centro" value={bairro} onChange={ 
                                                e => {                               
                                                    setBairro(e.target.value);
                                                }}/>
                                        <ErrorMessage name="bairro" component="div" className="error-message"/>

                                        <label htmlFor="complemento">Complemento</label>
                                        <Field type="text" name="complemento" placeholder="Apartamento" value={complemento} onChange={ 
                                                e => {                               
                                                    setComplemento(e.target.value);
                                                }}/>
                                        <ErrorMessage name="complemento" component="div" className="error-message"/>       

                                        <label htmlFor="nomeEntrega">Referência entrega:</label>
                                        <Field type="text" name="nomeEntrega" placeholder="Nome Topiqueiro ou transportadora" value={nomeEntrega} onChange={ 
                                                e => {                               
                                                    setNomeEntrega(e.target.value);
                                                }}/>
                                        <ErrorMessage name="nomeEntrega" component="div" className="error-message"/>       
                                        
                                        <div className="submit-box">                                                                
                                            <div>
                                                <button type="submit" disabled={isSubmitting} className="btn-delivery">
                                                    Continuar
                                                </button> 
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>                     
                            <ToastContainer /> 
                        </div>        
                    }
                </div>
            }
        </div>
    )
};

export default ShoppingCartDelivery;
            