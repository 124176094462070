import React, {Component, useState, useEffect} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './styles.css';
import { Container } from '../../../node_modules/react-bootstrap';
import { ToastContainer, toast } from '../../../node_modules/react-toastify';
import { useLocation } from "react-router-dom";
import api from '../../services/api';
import { UsuarioSessao } from "../../business/usuarioSessao";

const FormLogin = (props) => {
    let location = useLocation();
    
    if (location.search.search("atualizar_senha") > 0 && props.foi_notificado == false) {        
        setTimeout(function(){    
        toast.info( "Sua senha atual é temporária. Atualize sua senha", {
            position: toast.POSITION.BOTTOM_RIGHT
        });
        }, 300);
        props.setFoiNotificado();
    }

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={props.usuario}
                validate={values => {
                    const errors = {};
                    if (!values.celular) {
                        errors.celular = 'Seu contato do Whatsapp é obrigatório!';
                    } 
                    if ( !values.nome ) {
                        errors.nome = 'O nome é obrigatória!';
                    }
                    return errors;
                }}
                onSubmit={ (values, {setSubmitting, setErrors, setStatus, resetForm}) => {
                    let param = {       
                        usuario: {}
                    };

                    if ( props.usuario.nome != values.nome ){
                        param.usuario.nome = values.nome;
                    }
                    if ( props.usuario.celular != values.celular ){
                        param.usuario.celular = values.celular+"";
                    }
                    if ( values.senha ){
                        param.usuario.senha = values.senha;
                    }

                    api.put('/usuario/usuarios/'+props.usuario.id, param)
                    .then(function (response) {
                        // handle success
                        setSubmitting(false);

                        
                        toast.success( "Atualizado com Sucesso! ", {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });

                    })
                    .catch(function (error) {

                        let message_error = "Problema ao realizar atualização.";
                        if (typeof error.response != "undefined" && typeof error.response.data != "undefined" && typeof error.response.data.error != "undefined") {
                            message_error += typeof error.response.data.error != "undefined" ? error.response.data.error : "";
                        }
                        
                        setSubmitting(false);   

                        toast.error( message_error, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    });
                }}
                >
                {({ isSubmitting }) => (
                    <Form className="form-login">
                        <label htmlFor="nome">Proprietário/Responsável</label>
                        <Field type="nome" name="nome" placeholder="Nome do proprietário ou responsável" />
                        <ErrorMessage name="nome" component="div" className="error-message"/>

                        <label htmlFor="celular">Whatsapp(DDD+NUMERO)</label>
                        <Field type="number" name="celular" placeholder="00000000000"/>
                        <ErrorMessage name="celular" component="div" className="error-message"/>
                        
                        <label htmlFor="senha">Nova Senha</label>
                        <Field type="password" name="senha" placeholder="Senha para acesso futuro"/>
                        <ErrorMessage name="senha" component="div" className="error-message"/>                        
                        
                        <div className="submit-box">
                            <button type="submit" disabled={(isSubmitting)}>
                                Salvar
                            </button>
                        </div>
                    </Form>
                )}
                </Formik>
                <ToastContainer />
        </div>
    );
}


export default class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foi_notificado: false,
            showModal: false,
            usuario: {}
        };
    }

    componentDidMount() {
        this.buscarUsuario();
    }

    buscarUsuario = async () => {
        let usuario_sessao = await UsuarioSessao();    
        this.setState({usuario: usuario_sessao});
    }
    
    handleFoiNotificado = () => {
        this.setState({foi_notificado: true})
    }

    handleModalClose = () => {
        this.setState({showModal:false})
    };

    handleModalShow = () => {
      this.setState({showModal:true})
    };

    render() {        
        return (
            <Container className="content-body">
                <h1>{this.state.usuario ? this.state.usuario.nome : ""}</h1>
                
                <FormLogin 
                    api={this.props.api} 
                    foi_notificado={this.state.foi_notificado} 
                    setFoiNotificado={this.handleFoiNotificado}                    
                    usuario={this.state.usuario}
                />                
            </Container>
        );
    }
}