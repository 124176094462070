import React from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import AdminFormCRUDUser from '../adminFormCRUDUser';
import './styles.css';
import 'react-dropzone-uploader/dist/styles.css';
import { ToastContainer, toast } from 'react-toastify';

const AdminModalCRUDUser = (props) => {
    return (
      <>        
        <Modal show={props.show} onHide={props.handleClose} dialogClassName="modal-container">
          <Modal.Header closeButton>
            <Modal.Title>Usuário
                {props.usuario &&                     
                    <a href={"https://api.whatsapp.com/send?1=pt_BR&phone=55"+props.usuario.celular} target="_blank"> - Abrir Whats</a>
                }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>                    
            <Tabs defaultActiveKey="produto" id="uncontrolled-tab-example">
              <Tab eventKey="produto" title="Produto">
                <AdminFormCRUDUser 
                  usuario={props.usuario} 
                  handleCloseMainModal={props.handleClose}
                  handleAddUser={props.handleAddUser} 
                  handleEditUser={props.handleEditUser}
                />
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
        <ToastContainer />
      </>
    );
  }
  
  export default AdminModalCRUDUser;