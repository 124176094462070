import React from 'react';
import "./styles.css";

const AdminNavigation = (props) => {
    return (       
        <aside className="admin-nav">
            <ul>
                <li><a href="/admin/produtos">Produtos</a></li>
                <li><a href="/admin/usuarios">Usuários</a></li>
                <li><a href="/admin/pedidos">Vendas</a></li>                
                <li>Estoque</li>
                <li><a href="/admin/notificacao">Notificação SMS</a></li>
            </ul>
        </aside>
    );
}

export default AdminNavigation;